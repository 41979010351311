.box-shadow-top {
  .box-shadow(0px 1px 10px rgba(0, 0, 0, 0.25));
}

// https://www.cssmatic.com/box-shadow
.box-shadow-medium {
  .box-shadow(0px 10px 20px -10px rgba(0,0,0,0.2));

  @media (max-width: @screen-sm-max) {
    .box-shadow(0px 10px 20px -5px rgba(0,0,0,0.1));
  }

    @media (max-width: @screen-sm-max) {
    &.sm-shadow-bottom-only {
      .box-shadow(0px 30px 60px -20px rgba(0,0,0,0.2));
    }
  }
}
.box-shadow-medium-intense {
  .box-shadow(0px 10px 20px -5px rgba(0, 0, 0, 0.4));
}

.box-shadow-medium-hover {
  .box-shadow(0px 10px 60px -19px rgba(0, 0, 0, 0.5));
}

.box-shadow-small {
  .box-shadow(0px 0px 20px -7px rgba(0, 0, 0, 0.25));
}

.box-shadow-tiny {
  .box-shadow(0px 0px 16px -5px rgba(0, 0, 0, 0.1));
}

.box-shadow-bottom-small {
  .box-shadow(0px 1px 4px 0 rgba(0, 0, 0, 0.15));
}

.image-gradient-overlay {
  width: 100%;
  height: 100%;
  display: block;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    height: 100%;
    width: 100%;

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0+100 */
    background: -moz-linear-gradient(45deg, rgba(0,0,0,0.45) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, rgba(0,0,0,0.45) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, rgba(0,0,0,0.45) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  }
}

.bg-gradient-horizontal-grey {
  background: rgba(245,245,245,1);
  background: -moz-linear-gradient(left, rgba(245,245,245,1) 0%, rgba(255,255,255,1) 15%, rgba(255,255,255,1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(245,245,245,1)), color-stop(15%, rgba(255,255,255,1)), color-stop(100%, rgba(255,255,255,1)));
  background: -webkit-linear-gradient(left, rgba(245,245,245,1) 0%, rgba(255,255,255,1) 15%, rgba(255,255,255,1) 100%);
  background: -o-linear-gradient(left, rgba(245,245,245,1) 0%, rgba(255,255,255,1) 15%, rgba(255,255,255,1) 100%);
  background: -ms-linear-gradient(left, rgba(245,245,245,1) 0%, rgba(255,255,255,1) 15%, rgba(255,255,255,1) 100%);
  background: linear-gradient(to right, rgba(245,245,245,1) 0%, rgba(255,255,255,1) 15%, rgba(255,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5f5f5', endColorstr='#ffffff', GradientType=1 );
}