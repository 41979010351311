.text-bold {
  font-weight: bold;
}

.text-underline {
  text-decoration: underline;
}

.text-jumbo {
  font-size: @font-size-jumbo !important;
  @media (max-width: @screen-xs-max) {
    font-size: @font-size-jumbo/1.5 !important;
  }
}

.text-extra-jumbo {
  font-size: @font-size-jumbo*1.2 !important;
  @media (max-width: @screen-xs-max) {
    font-size: @font-size-jumbo/1.2 !important;
  }
}

.text-sm, .text-small {
  font-size: @font-size-small;
}

.text-xs {
  font-size: ceil(@font-size-small/1.1);
}

.text-md {
  font-size: @font-size-base;
}

.text-lg {
  font-size: @font-size-large;

  @media (max-width: @screen-sm-max) {
    font-size: 15px;
  }
}

.text-xl {
  font-size: ceil((@font-size-base * 1.5));
}

.text-xxl {
  font-size: ceil((@font-size-base * 2));

  @media (max-width: @screen-sm-max) {
    font-size: ceil((@font-size-base * 1.5));
  }
}

.cursor-pointer {
  cursor: pointer;
}


a[ng-click]:hover {
  cursor: pointer;
}

a:hover, a:focus {
  text-decoration: none;
}

.text-muted a.color-inherit:hover {
  color: @darkest-grey !important;
}

h1, h2, h3, h4, h5, h5, .headline-color,
h1 a, h2 a, h3 a, h4 a, h5 a, h5 a, .headline-color a,
.h1, .h2, .h3, .h4 {
  color: @black;
  line-height: 1.2;
  word-wrap: break-word;
  margin-top: 0;
  font-weight: bold;

  &:hover {
    text-decoration: none;
  }
}

h1, .h1 {
  margin-bottom: 24px;

  @media (max-width: @screen-sm-max) {
    font-size: 22px;
    margin-bottom: 16px;
  }
}

h2, .h2 {
  margin-bottom: 27/2px;

  @media (max-width: @screen-sm-max) {
    font-size: 20px;
    margin-bottom: 16px;
  }
}

h3, .h3 {
  @media (max-width: @screen-sm-max) {
    font-size: 18px;
  }
}

h4, .h4 {
  @media (max-width: @screen-sm-max) {
    font-size: 16px;
  }
}

.break-word {
  word-wrap: break-word;
}

.text-nowrap {
  white-space: nowrap;
}

.text-overflow-ellipsis {
  .text-overflow();
}

.line-height-sm {
  line-height: 1.15;
}