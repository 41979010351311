body {
  font-size: @font-size-base;
  font-style: normal;
  background: #f5f5f5;
  -webkit-font-smoothing: subpixel-antialiased;
  overflow-x: hidden;
  color: @base-font-color;
}

img {
  max-width: 100%;
}

table img {
  max-width: none;
}

[ng\:cloak], [ng-cloak], .ng-cloak {
  display: none !important;
}

.hide-scroll-bars::-webkit-scrollbar {
  display: none;
}

.top-space-content {
  padding-top: @grid-gutter-width;

  @media (max-width: @screen-sm-max) {
    padding-top: @grid-gutter-width/2;
  }
}

.top-space-voucher {
  margin-top: -450px;

  @media (max-width: @screen-sm-max) {
    margin-top: 0;

    &.container {
      padding: 0;
    }
  }
}

.top-space-challenge {
  margin-top: -30px;
  @media (max-width: @screen-sm-max) {
    margin-top: 15px;
  }
}

.top-space-none {
  padding-top: @content-top-no-margin;

  @media (max-width: @screen-sm-max) {
    padding-top: @navbar-mobile-height;

    &.top-space-none-slim {
      padding-top: @navbar-height;
    }
  }
}

.top-space-zero {
  padding-top: 0;
}

.top-buffer {
  margin-top: 20px;
}

.stroke-grey {
  width: 100%;
  height: 1px;
  background: #dedede;
  margin: 15px auto 15px;
}

.stroke-small-red {
  .red;
  width: 15%;
  height: 1px;
  margin: 0 auto 15px;
}

.navbar-brand-container {
  width: 165px;
  height: 36px;

  .btn-dropdown-circle {
    margin-top: 3px;
  }

  .navbar-dropdown {
    margin-left: -4px;
  }

  @media (max-width: @screen-sm-max) {

    margin: 5px auto -5px;
    width: 100%;

    .navbar-dropdown {
      position: absolute;
      left: 50%;
      transform: translate(165%, 0%);
      z-index: 2;
    }

    .btn-dropdown-circle {
      margin-top: -3px;
    }

  }
}

.navbar-brand {
  .bg-logo;
  margin-top: -5px;
  margin-bottom: 5px;
  width: 140px;
  height: 40px;
  display: inline-block;
  margin-left: 0 !important;
  background-position: left;
  background-size: auto 40px;
  background-repeat: no-repeat;

  &.navbar-brand-bday {
    background-image: url("/assets/img/logo/iamstudent_bday.svg");
  }

  @media (max-width: @screen-sm-max) {
    position: absolute;
    left: 50%;
    margin-top: -3px;
    transform: translate(-50%, 0%);
    width: 103px;
    height: 30px;
    background-size: auto 30px;
  }
}

.label {
  text-transform: uppercase;
}

//.container-extended {
//  .container;
//  width: 1440px !important;
//  padding: 0 !important;
//
//  @media (max-width: @screen-md-max) {
//    width: 100% !important;
//  }
//}


.main-content p a {
  text-decoration: underline;
}

@media (max-width: @screen-sm-max) {
  //.container-extended {
  //  width: 100% !important;
  //}
  .container.container-full-mobile {
    padding-left: 0;
    padding-right: 0;

    &:not(.has-dropdown) {
      overflow-x: hidden;
    }
  }

  .container {
    padding-left: @grid-gutter-width/2;
    padding-right: @grid-gutter-width/2;
  }

  .main-content {
    overflow: hidden;
  }
}

/*
 * MAIN NAV
 */
#nav-main {
  transition: top 300ms;

  > .container-fluid {
    //max-width: 1320px;
  }
}

.navbar-main {
  height: @navbar-height;
  padding: 10px 0;
  background: @white;
  border: none;
  z-index: 1030;

  .navbar-nav > li > {
    margin-right: 10px;

    > a {
      .border-radius(5px);
      padding: 8px 10px;
      color: @black;
      //font-weight: bold;
      line-height: 1;

      .fa {
        //font-size: 14px;
        //margin-right: 5px;
      }

      .label-primary {
        .border-radius(10px);
        position: absolute;
        top: 0;
        right: 0;
        padding: 0.2em 0.4em;
        min-height: 6px;
        min-width: 6px;
        display: block;

        &.label-bullet {
          padding: 0;
          top: 2px;
          right: 2px;
        }
      }
    }

    &.active,
    > a:hover, > a:active, > a:focus {
      background-color: @brand-primary;
      .border-radius(5px);

      & > a {
        color: @white !important;
      }
    }

    &:not(.active) > a {
      &:hover,
      &:active,
      &:focus {
        background-color: @navbar-default-link-color;
        .transition(all 0.25s ease-in-out);
      }
    }

    // Last nav item no margin to right
    &:last-child {
      margin-right: 0;
    }

    @media (max-width: @screen-sm-max) {
      &:first-child {
        //margin-left: 5px;
      }

      &:last-child {
        //margin-right: 5px;
      }

      margin-right: 5px;
    }

    > .dropdown-menu {
      .border-radius(5px);
    }
  }


  @media (max-width: @screen-sm-max) {
    padding: 5px 0;

    .navbar-nav.navbar-scroll {
      display: flex;
      flex-direction: row;
      //justify-content: space-between;
      white-space: nowrap;
      overflow: auto;
      padding-left: 5px;
      padding-right: 5px;
      //margin: 0 -@grid-gutter-width/2;
      //column-gap: 5px;

      -ms-overflow-style: none;
      scrollbar-width: none;

      li {
        //margin: 0;
      }

      &.open {
        overflow: unset;
      }

      &:after,
      &:before {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 15px;
        height: 100%;
        background: linear-gradient(90deg, #ffffff00 0%, #ffffff 100%);
        z-index: 2;
        pointer-events: none;
      }

      &:before {
        left: 0;
        right: auto;
        background: linear-gradient(270deg, #ffffff00 0%, #ffffff 100%);
      }

      &::-webkit-scrollbar {
        display: none;
      }

    }
  }

  .navbar-float-right > li {
    float: right;
    margin-right: 0;
    margin-left: 10px;

    &:last-child {
      margin-left: 0;
    }
  }

  #navbar-collapse-search {
    @media (max-width: @screen-sm-max) {
      margin: 5px;
    }
  }

  .navbar-search {
    margin: 0;
    padding: 0;
    border: none;
    background: @navbar-default-link-color;
    .border-radius(5px);
    //overflow: hidden;

    .btn {
      background: @navbar-default-link-color;
      font-size: 14px;
      height: 26px;
      margin: 2px;
      color: #555555;
      padding: 6px;
      .border-radius(5px);
    }

    .form-control {
      background: @navbar-default-link-color;
      height: 30px;
      padding: 0 34px;
      text-align: center;
      .transition(border 0.25s ease-in-out);
      border: 1px solid black;

      &:hover, &:active, &:focus {
        border: 2px solid @brand-secondary !important;
      }
    }

    .form-control-feedback {
      left: 0;
      pointer-events: all;

      &.btn-right {
        right: 0;
        left: auto;
      }
    }
  }

  #trending-search-box {
    .box-shadow-medium-intense;
    z-index: 999;
    position: absolute;
    top: 30px;
    background: white;
    min-height: 275px;
    max-height: 450px;
    overflow-x: auto;
    overscroll-behavior: contain;
    .border-radius(5px);
    width: 100%;
    transition: height 0.4s;

    .title {
      padding: @grid-gutter-width/2;
    }

    ul.list-unstyled a {
      display: block;
      padding: @grid-gutter-width/2;
      color: @black;
      line-height: 1;
      border-top: 1px solid #efefef;

      &:hover,
      &:active,
      &focus {
        background-color: @navbar-default-link-color;
        .transition(all 0.25s ease-in-out);
      }
    }

    @media (max-width: @screen-sm-max) {
      width: 100%;
      max-width: none;
      top: 30px;
      //left: @grid-gutter-width/2;
      //right: @grid-gutter-width/2;
    }

    .nav-tabs-secondary > li > a {
      padding: 12px 10px;

      .info {
        position: unset !important; // disable position absolute (number above text from nav-tabs.less)
      }
    }

    // List elements for autocomplete
    li {
      .elem-image {
        margin-right: 10px;
        .border-radius(3px);
        overflow: hidden;
      }

      .elem-infos {
        display: block;
      }

      .elem-title {
        font-weight: bold;
        display: block;
        margin-bottom: 5px;
      }

      .elem-sub {
        .text-muted;
        display: block;
      }
    }
  }

  // <- end trending search box


  @media (max-width: @screen-sm-max) {
    height: auto;
    min-height: @navbar-mobile-height;
    //#navbar-collapse-search #trending-search-box {
    //  display: none;
    //}
    //
    //#navbar-collapse-search.in #trending-search-box {
    //  display: block;
    //}
  }

  @media (max-width: @screen-sm-max) {
    > .container-fluid {
      padding: 0;
    }

    .navbar-toggle-mobile,
    .navbar-toggle {
      padding: 12px 10px !important;
      .border-radius(25px) !important;

      //&.user-button {
      //  margin-right: 5px;
      //}

      //&.search-button {
      //  float: left;
      //  margin-left: 5px;
      //}

      &:hover,
      &:active,
      &focus {
        background-color: @navbar-default-link-color;
        .transition(all 0.25s ease-in-out);
      }
    }
  }

  .navbar-toggle {
    color: @black;
    margin: 0;
    border: none;
    .border-radius(0);
    line-height: 1;

    &:active,
    &:hover {
      text-decoration: none;
    }
  }
}


.navbar, .navbar-nav {
  margin: 0;

  .divider {
    .dropdown-menu .divider;
  }
}

.navbar.navbar-default {
  //height: @navbar-sub-height;
  min-height: @navbar-sub-height;
  z-index: 1020;

  &.navbar-sub-menu {
    &:not(.navbar-fixed-bottom) {
      top: @navbar-height;
    }

    background: #2e2e2e;
    border-width: 0;

    li {
      > a {
        color: @white;
        font-size: 13px;
        padding: 14px 8px;
        line-height: 1;
        font-weight: normal;

        &:hover,
        &:active,
        &:focus {
          color: @brand-secondary;;
          background: none;
          .transition(all 0.25s ease-in);
        }
      }

      &.active {
        > a {
          background: none;
          color: @brand-secondary;
        }
      }
    }

    .dropdown-menu {
      background: #2e2e2e;
      border: none;
      min-width: 180px;
    }

    @media (max-width: @screen-sm-max) {
      &:not(.navbar-fixed-bottom) {
        top: @navbar-mobile-height !important;

        .navbar-nav {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          white-space: nowrap;
          overflow: auto;
        }

        //li {
        //  padding: 0 4px;
        //}

        .container-fluid {
          padding: 0;
        }

      }

      &.navbar-sides-fadeout {
        .navbar-nav:after,
        .navbar-nav:before {
          display: block;
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          width: 20px;
          height: 100%;
          background: linear-gradient(90deg, #2f2f2f00, #2e2e2e);
          z-index: 2;
          pointer-events: none;
        }

        .navbar-nav:before {
          left: 0;
          right: auto;
          background: linear-gradient(270deg, #2f2f2f00, #2e2e2e);
        }
      }
    }

    @media (max-width: @screen-xs-max) {
      &.navbar-sides-fadeout .navbar-nav {
        justify-content: space-between;
      }
    }

    @media (max-width: @screen-xs-max) {
      &:not(.navbar-fixed-bottom) .navbar-nav {
        justify-content: space-between;
      }
    }
  }

  //.navbar-centered {
  //  text-align: center;
  //  height: @navbar-centered-height;
  //
  //  .navbar-nav {
  //    float: none;
  //    display: inline-block;
  //  }
  //}

  &.navbar-default-invert {
    background: @white;

    li {
      width: auto !important;

      a {
        color: @black;
        font-weight: bold;
      }

      a:not(.collapsed) {
        color: @brand-secondary;
      }
    }
  }
}

// prevent overflow in searchbox
#navbar-collapse-search.navbar-collapse.in {
  overflow: unset !important;
}

.navbar-fixed-top .navbar-collapse {
  max-height: 400px;
}

.dropdown-menu {
  border: none;
  //.border-radius(0);
  padding: 0;

  .divider {
    margin: 0;
  }
}

@media (max-width: @screen-xs-max) {
  .navbar-nav .open .dropdown-menu {
    position: absolute;
    background: @white;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  }
}

.navbar-dropdown {
  //background: @white;

  > li ul.dropdown-menu > li > a,
  > li ul.dropdown-menu > li > span,
  ul.navbar-nav li > a,
  ul.navbar-nav li > span {
    display: block;
    padding: 10px @grid-gutter-width/2;
    font-size: 14px;
    line-height: 1;
    //font-weight: bold;
    .transition(all 0.25s ease-in-out);

    @media (max-width: @screen-sm-max) {
      padding: 10px 15px !important;

    }
  }

  .dropdown-menu {
    left: 0;
    right: auto;
    overflow: hidden;
    //width: 250px;
  }
}

&.navbar-right {
  .dropdown-menu {
    right: 0;
    left: auto;
  }
}

.navbar-collapse {
  padding: 0;
  border: none;
}

.nav-profile-placeholder {
  min-width: 163px;

  @media (max-width: @screen-sm-max) {
    position: absolute !important;
    right: 5px;
    top: 5px;
    min-width: 80px;
  }
}

.inline-padded-form {
  background: @white;
  .border-radius(4px);
  padding: 7px;

  input {
    .pull-left;
    border: none;
    box-shadow: none;
    width: 69%;

    &:focus {
      box-shadow: none;
    }

    @media (max-width: @screen-xs-max) {
      width: 100%;
    }
  }

  button {
    .pull-right;
    @media (max-width: @screen-xs-max) {
      width: 100%;
    }
  }

}

/* FOOTER */
footer.footer-layout {
  background: @footer-grey;
  margin-top: @grid-gutter-width;

  .container {
    padding-top: @grid-gutter-width;
    padding-bottom: @grid-gutter-width;

    .h4 {
      margin: 0;
      padding-bottom: 10px;
      color: @white;
      font-size: 18px;

      @media (max-width: @screen-sm-max) {
        margin-top: 25px;
      }

      a {
        display: block;
      }
    }

    nav ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding: 3px 0;
        font-size: 14px;
        @media (max-width: @screen-sm-max) {
          padding: 10px 0;

          a {
            display: block;
          }
        }
      }
    }

    a {
      color: @medium-grey;
      transition: all .4s ease;

      &:hover {
        color: @white !important;

        text-decoration: none;

        .fa-icon {
          color: @brand-primary;
        }
      }
    }

    [data-toggle="collapse"]:after {
      float: right;
      margin-right: 5px;
    }

    [data-toggle="collapse"]:after {
      content: "-";
    }

    [data-toggle="collapse"].collapsed:after {
      content: "+";
    }

    @media (min-width: @screen-sm-min) {
      nav.collapse {
        display: block;
        margin-bottom: 20px;
      }
    }

  }

}

/* HTML anchors */
a[name] {
  display: block;
  position: relative;
}

a {
  &.text-white {
    &:hover {
      color: @white;
    }
  }
}

.footer-fixed {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  p {
    margin: 0;
  }

  .btn-use-voucher.btn-lg {
    padding: 30px 40px;

    .fa-icon {
      top: 27px;
    }
  }

  @media (max-width: @screen-xs-max) {

    .btn-use-voucher.btn-lg {
      padding: 20px 40px;

      .fa-icon {
        top: 14px;
      }
    }
  }
}

.bottom-notification {
  z-index: 1050;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 400px;

  @media (max-width: @screen-xs-min) {
    width: 100%;
  }

  > div {
    padding: 20px;

    @media (max-width: @screen-xs-min) {
      padding: 10px;
    }
  }
}

.headline-more-link {
  display: inline-block;
  padding: 9px 0;

  @media (max-width: @screen-sm-max) {
    padding: 0;
  }
}

/* ERROR STYLE */
.block-exception,
.list-exception,
.block-exception strong,
.list-exception strong,
.traces {
  font-family: Arial;
}

.user-status-logged-out {
  .hidden-logged-out {
    .hidden;
  }
}

.user-status-logged-in {
  .hidden-logged-in {
    .hidden;
  }

  .user-status-mobile {
    .text-primary;
  }
}

/* z-index for photo lightbox */
.mfp-wrap,
.mfp-bg {
  z-index: 1100;

  @media (min-width: @screen-md-max) {
    .mfp-content {
      min-width: 680px;
    }
  }
}

.mfp-bottom-bar.mfp-title-advanced {
  bottom: 40px;
  margin-top: 0;
  top: auto;

  .mfp-title {
    padding: 0;

    .title-overlay {
      margin: 0 auto;
      background: rgba(0, 0, 0, 0.7);
      padding: 10px;
      font-size: 18px;
      line-height: 24px;

      @media (max-width: @screen-sm-max) {
        font-size: 14px;
        line-height: 18px;
      }

      .btn {
        margin-left: 4px;

        &:last-child {
          margin-left: 10px;
        }
      }
    }
  }

  .mfp-counter {
    bottom: -20px;
    top: auto;
  }
}

.mfp-iframe-scaler .mfp-bottom-bar {
  bottom: 0;
}

/* Prevent breaking iframes the design on mobile screens */
.panel iframe,
.panel object,
.panel embed {
  max-width: 100%;
  max-height: 100%;
}

/* Link in headline; Right */
.headline-side-link {
  margin-top: 5px;

}

/* COOKIE BANNER */
#cookie-banner {
  display: none;
  position: fixed;
  z-index: 9999;
  left: @grid-gutter-width/2;
  bottom: @grid-gutter-width/2;
  background: rgba(0, 0, 0, 0.8);
  color: @white;
  padding: @grid-gutter-width/2;
  width: 330px;

  a {
    color: @white;
    text-decoration: underline;
  }

  @media (max-width: @screen-xs-min) {
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 10px;
  }
}

/* Fix clickable show between element (e.g. sticky footer on voucher page) */
[show-between].fade {
  &:not(.in) {
    visibility: hidden;
  }
}

#iam-dev {
  display: none !important;
  .progress-bar;
  .progress-bar-striped;
  position: fixed;
  top: 0;
  height: 30px;
  width: 100%;
  z-index: 1100;
  line-height: 28px;
  animation: progress-bar-stripes 2s linear infinite;
  opacity: 0.7;

  &::before {
    content: "Warning: This is the dev environment"
  }
}

.fb-page-plugin-container {
  margin-bottom: @grid-gutter-width/2;
}

/* Map Styles */
#map {
  height: 600px;
  width: 100%;

  @media (max-width: @screen-xs-min) {
    height: 400px;
  }

}

@media (min-width: @screen-sm-min) {
  .geocoder {
    position: absolute;
    z-index: 1;
    width: 250px;
    left: 10px;
    top: 10px;
    opacity: 0.9;
  }
}

/* Support Styles */
.support-category-box {
  font-size: 17px;

  a {
    padding: 20px;
  }

  @media (max-width: @screen-xs-min) {
    font-size: 15px;
    a {
      padding: 14px;
    }
  }
}

/* Add fixed height wrapper for lazy loaded images to prevent high Cumulative Layout Shift (CLS) */
[style*="--aspect-ratio"] > :first-child {
  width: 100%;
}

[style*="--aspect-ratio"] > img {
  height: auto;
}

@supports (--custom:property) {
  [style*="--aspect-ratio"] {
    position: relative;
  }

  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }

  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}

// Define global variables for cookieconsent
#cc--main {
  --cc-z-index: 1500 !important;
}

// Copied from media.less bootstrap
// Media list variation
//
// Undo default ul/ol styles
.media-list {
  padding-left: 0;
  list-style: none;
}

.card-columns {
  column-count: 4;
  column-gap: @grid-gutter-width/2;
  orphans: 1;
  widows: 1;

  li {
    display: inline-block; // Don't let them vertically span multiple columns
    width: 100%; // Don't let their width change
    padding-bottom: 5px;
  }

  @media (max-width: @screen-sm-max) {
    column-count: 2;
    column-gap: @grid-gutter-width/2;
    orphans: 1;
    widows: 1;
  }
}

hr.dark {
  border-color: @medium-grey;
}