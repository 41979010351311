.feed-item {
  .box-shadow-medium;
  position: relative;
  background: @white;
  margin-bottom: @grid-gutter-width;
  overflow: hidden;

  .feed-item-body {
    display: block;
    padding: @grid-gutter-width;

    @media (max-width: @screen-sm-max) {
      padding: @grid-gutter-width/2;
      font-size: 13px;
    }

    .feed-item-text {
      display: block;
      margin-top: @grid-gutter-width/2;
    }
  }

  .logo-wrapper {
    .logo-title {
      font-weight: bold;
      color: @headline-color;
      font-size: 15px;
      line-height: 1.1;

      &:hover {
        color: inherit !important;
      }
    }

    .company-logo {
      width: 50px;
      height: 50px;
      float: left;
      margin: 0 @grid-gutter-width/4 0 0;
    }
  }

  .image-wrapper {
    position: relative;
    display: block;

    .image-title {
      position: absolute;
      bottom: 0;
      padding: @grid-gutter-width;;
      color: @white;
      font-size: 22px;

      @media (max-width: @screen-sm-max) {
        padding: @grid-gutter-width/2;
        font-size: 17px;
      }
    }
  }

  .feed-share-wrapper {
    .btn-share {
      padding: 6px 5px;
      font-size: 13px;
      @media (max-width: @screen-xs-max) {
        display: inline !important;
      }
    }
  }
}