a.text-plus {
  text-decoration: none !important;
  border-bottom: 2px solid;

  &:hover {
    color: @color-plus-darker;
    cursor: pointer;
  }
}

.plus-thanks-img {
  position: relative;
  background-image: url('/assets/img/iamstudent/specials/plus/iamstudent_plus_thank_you_box_desktop.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 561px;
  width: 800px;
  margin: 0 auto;

  @media (max-width: @screen-sm-max) {
    height: 495px;
    width: 100%;
  }

  @media (max-width: @screen-xs-max) {
    height: 370px;
    width: 100%;
    background-image: url('/assets/img/iamstudent/specials/plus/iamstudent_plus_thank_you_box_mobile.jpg');
  }

  @media (max-width: 500px) {
    height: 270px;
    width: 100%;
  }

  .plus-thanks-user-name {
    color: @white;
    position: absolute;
    top: 60px;
    left: 39%;
    transform: rotate(-1.5deg);

    max-width: 375px;
    font-size: 36px;
    line-height: 1;
    text-align: left;
    letter-spacing: -0.03em;

    @media (max-width: @screen-sm-max) {
      font-size: 30px;
      top: 50px;
    }

    @media (max-width: @screen-xs-max) {
      font-size: 22px;
      top: 23px;
      max-width: 260px;
      left: 41%;
    }

    @media (max-width: 500px) {
      font-size: 22px;
      top: 26px;
      max-width: 180px;
      left: 39%;
    }
  }
}

.plus-payments {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  img {
    margin-right: 8px;
    margin-bottom: 8px;
    background: white;
    padding: 5px 10px;
    border-radius: 2px;
    box-shadow: 0 0 2px 0px #0000001c;
    height: 24px;
  }
}

.plus-section {
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-top: 4rem;

  .tns-outer .tns-nav > button[aria-controls].tns-nav-active:after {
    background: @color-plus-darker;
  }
}

.plus-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;

  small a {
    border-bottom: none;
  }

  &.text-center {
    display: block;
  }

  @media (min-width: @screen-sm-min) {
    font-size: 23px;
  }
}

.plus-quotes {
  padding: 4rem 0;

  ul {
    display: flex;
    flex-wrap: wrap;
    max-width: 850px;
    margin: 1rem auto;
  }

  &.expand-bg-color:before {
    background-color: @color-plus-gray-light;
  }
}

.plus-divider {
  background-color: @color-plus;
  opacity: 0.5;
  border-top: 0;
  height: 2px;
  width: 50%;
  margin-top: 4rem;
}


.plus-bubble-container {
  width: 310px;
  padding: 1.5rem;
  color: @black;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .textbubble {
    background-color: @color-plus;
    box-shadow: 8px 8px 0 0 currentColor;
  }
  .textbubble:after {
    background-color: @color-plus;
    border-top: none;
  }
  .textbubble:before {
    left: calc(1em + 25px);
    bottom: calc(-1.4em - 4px);
    border-right: 3em solid transparent;
    border-top: 1.5em solid currentColor
  }

  .plus-reviewer {
    color: @white;
    background: @black;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    margin-left: -16px;
    margin-top: 12px;
  }

  @media (min-width: @screen-md-min) {
    width: 380px;
    padding: 2.2rem;
  }
}

.plus-carousel {
  max-width: 1000px;
  margin: 0 -15px;

  .carousel-inner {
    max-width: calc(100% + 30px);
    width: 768px;
    margin: 0 auto;
  }

  .carousel-control {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4rem;
  }

  @media (min-width: @screen-sm-min) {
    margin: 0 auto;

    .carousel-inner {
      max-width: 100%;
    }

    .carousel-control.left {
      justify-content: flex-end;
      margin-left: -8rem;
      background: none;
    }

    .carousel-control.right {
      justify-content: flex-start;
      margin-right: -6rem;
      background: none;
    }
  }

}