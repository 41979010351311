/* BREADCRUMBS */
.breadcrumb {
  clear: both;
  background: none;
  font-size: 14px;
  margin: 0;
  padding: 0 0 12px;

  a, span {
    color: @text-grey;
  }

  a:hover {
    text-decoration: underline;
  }
  @media (max-width: @screen-sm-max) {
    margin: 0;
    padding: 0;
    font-size: 12px;
  }

  > li + li:before {
    padding: 0 0 0 5px;
  }
}

.breadcrumb-top .breadcrumb {
  padding: 0 0 12px 0;
}