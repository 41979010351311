.btn {
  border: none;
  line-height: 1;
  .transition(all 0.25s ease-in-out);
  .border-radius(@input-border-radius);
  text-decoration: none !important;

  .icon {
    position: absolute;
    left: 10px;
    top: 14px;
  }
}

.btn-iams-sm() {
  font-size: 11px;
  padding: 8px 10px;
}

.btn-iams,
.btn-flat,
.btn-secondary {
  background-color: @brand-primary;
  text-transform: uppercase;
  color: @white;
  font-size: 11px;
  padding: 16px 24px;
  margin: 0;
  font-weight: bold;


}

.btn.disabled {
  background-color: @light-grey;
  color: @text-grey;

  &:hover {
    background-color: @medium-grey;
    color: @text-grey;
  }
}

.btn-invert {
  background: @white;
  color: @brand-primary;
}

.btn-iams {

  &:hover,
  &:focus {
    color: @white;
    background-color: @brand-primary-darker;
  }

  &:active {
    color: @white;
    background-color: darken(@brand-primary, 10%);
  }

  &.btn-iams-sm {
    .btn-iams-sm();
  }

  &.btn-iams-lg {
    font-size: 14px;
  }

  &.btn-iams-xl {
    font-size: 14px;
    padding: 10px 30px;
  }

  &.btn-iams-jumbo {
    font-size: 16px;
    padding: 20px 40px;
  }

  &.btn-external {
    background: #f5c60c;
  }

  &.full-width {
    width: 100%;
  }
}

.btn-flat {
  border: 2px solid @white;

  &:hover,
  &:active,
  &:focus {
    color: @white;
    background-color: @brand-primary-darker;
  }

  &.btn-invert {
    border-color: @brand-primary;

    &:hover {
      border-color: @brand-primary-darker;
    }
  }

  &.btn-no-border {
    border: none;
  }

  &.btn-transparent {
    background: transparent;

    &:hover {
      color: @brand-primary;
      background: @white;
    }
  }
}

.btn-secondary {
  background-color: @brand-secondary;
  color: @white;

  &:hover,
  &:active,
  &:focus {
    color: @white;
    background-color: @brand-secondary-darker;
  }
}

.btn-primary {
  &.btn-xs {
    font-size: 12px;
    padding: 2px 6px;
    .border-radius(2px);
  }
}

.btn-default {
  .btn-iams;
  background-color: @button-default;
  color: @gray-dark;

  &:hover,
  &:focus,
  &:focus-visible {
    color: @gray-dark;
    background-color: darken(#e8e8e8, 5%);
    outline: none !important;
  }

  &:active {
    background-color: darken(#e8e8e8, 10%);
  }
}

.btn-success {
  .btn-success;
}

.btn-danger {
  .btn-danger;
}

.btn-warning {
  .btn-warning;
}

.btn-fb,
.btn-messenger,
.btn-twitter,
.btn-whatsapp,
.btn-instagram,
.btn-apple,
.btn-google,
.share-button-container .btn-default {
  .btn-iams;

  &.btn-share {
    padding: 14px;
    font-size: 14px;

    span {
      font-size: 12px;
    }
  }

  &.btn-sm {
    padding: 3px 5px;
    font-size: 11.5px;
    text-transform: none;
    letter-spacing: 0;
    font-weight: bold;
    vertical-align: top;
  }

  &.btn-sm-centered {
    .border-radius(30px);
    font-size: 16px;
    padding: 5px 20px;
  }
}

.btn-fb {
  background-color: @color-facebook;

  &:hover,
  &:active,
  &:focus {
    background-color: darken(@color-facebook, 5%);
  }
}
.btn-apple {
  background-color: @color-apple;

  &:hover,
  &:active,
  &:focus {
    background-color: darken(@color-apple, 5%);
  }
}
.btn-google {
  color: #565656;
  background: @color-google;
  border: 1px solid @form-grey;

  &:hover,
  &:active,
  &:focus {
    color: #565656;
    background-color: @light-grey;
  }
}

.btn-fb,
.btn-apple,
.btn-google {
  position: relative;
  .fa-icon {
    position: absolute;
    left: 10px;
    top: 14px;
  }
}

.btn-messenger {
  background-color: @color-messenger;

  &:hover,
  &:active,
  &:focus {
    background-color: darken(@color-messenger, 5%);
  }
}

.btn-whatsapp {
  background-color: @color-whatsapp;

  &:hover,
  &:active,
  &:focus {
    background-color: darken(@color-whatsapp, 5%);
  }
}

.btn-instagram {
  background-color: @color-instagram;

  &:hover,
  &:focus {
    background-color: darken(@color-instagram, 5%);
  }

  &:active {
    background-color: darken(@color-instagram, 10%);
  }
}

.btn-ios {
  background-color: @color-ios-bg;
  color: white;

  &:hover,
  &:active,
  &:focus {
    color: white;
    background-color: darken(@color-ios-bg, 5%);
  }
}

.btn-android {
  background-color: @color-android-bg;
  color: white;

  &:hover,
  &:active,
  &:focus {
    color: white;
    background-color: darken(@color-android-bg, 5%);
  }
}

.btn-plus-default {
  color: @light-grey;
  background-color: @color-plus-gray-light;

  &:hover,
  &:active,
  &:focus {
    color: @light-grey;
    background-color: darken(@color-plus-gray-light, 5%);
  }

  &.active {
    background-color: lighten(@color-plus-gray-light, 15%);
  }
}
.btn-plus {
  color: @color-plus-gray;
  background-color: @color-plus;

  &:hover,
  &:active,
  &:focus {
    color: @color-plus-gray;
    background-color: @color-plus-darker;
  }
}

.btn-slim {
  padding: 8px 12px;
}

.btn-wrapping {
  white-space: normal;
}

.btn-lg {
  font-size: 14px;
  padding: 20px 40px;
}

/* Position Icons in CTAs */
.fav-voucher,
.btn-use-voucher,
.btn-follow-company,
.btn-with-icon {
  position: relative;

  .fa-icon {
    position: absolute;
    left: 10px;
    top: 14px;
  }

  &.btn-lg .fa-icon {
    left: 15px;
    top: 16px;

    &.fa-plus {
      right: 10px;
    }
  }

  &.btn-slim .fa-icon {
    top: 7px;
    font-size: 12px;
  }

  &.filled {
    .fa-icon {
      color: @brand-primary;
      animation: pound 0.8s ease 2;
    }
  }
}

.btn-dropdown-circle {
  .border-radius(20px);
  background: transparent;
  padding: 8px 10px;

  &:hover,
  &:active,
  &:focus {
    background-color: @navbar-default-link-color;
    .transition(all 0.25s ease-in-out);
  }
}

.btn-icon {
  background: none;
  border: none;
  padding: 0;
  &:hover {
    .text-muted {
      color: @black;
    }
  }
}

@media (min-width: @screen-md-min) {
  // Auto open nav dropdown on hover
  .dropdown.dropdown-hoverable:hover .dropdown-menu {
    display: block;
  }
}