.box-item {
  .box-shadow-medium;
  position: relative;
  background: @white;
  margin-bottom: @grid-gutter-width;
  overflow: hidden;
  .border-radius(@panel-border-radius);

  a {
    display: block;

    .image-bg img {
      transition: opacity .2s ease-in;
      backface-visibility: hidden;
    }

    .h4 {
      transition: color .2s ease-in;
      backface-visibility: hidden;
      line-height: 1.3;
      display: block;
      color: @black;
      margin-bottom: 5px;
    }

    @media (min-width: @screen-md-min) {
      &:focus, &:active {
        text-decoration: none;
      }

      &:hover {
        text-decoration: none;

        .image-bg img {
          opacity: 0.7 !important;
        }

        .h4 {
          color: @brand-primary;
        }
      }
    }
  }

  @media (max-width: @screen-sm-max) {
    margin-bottom: @grid-gutter-width/2;
  }

  /* Voucher Box ************************************************************************ */

  &.voucher, &.challenge {

    a {
      height: 380px;
    }

    &.carousel a {
      height: 345px;
    }

    .box-item-body {
      display: block;
      padding: 30px;
    }

    .heart, .follow {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 3px;
      color: @text-grey;
      .transition(all 0.3s ease-in);
      backface-visibility: hidden;
      line-height: 1;

      &:hover {
        color: @brand-primary;
      }

      &.active {
        color: @brand-primary;
        animation: pound 0.8s ease 2;
      }

      svg {
        height: 18px;
      }

    }

    .image-bg {
      display: block;
    }

    .company-logo {
      .box-shadow-small;
      width: 84px;
      height: 84px;
      float: left;
      border: 6px solid @white;
      margin: -65px 0 15px;
      position: relative;
      background: @white;
    }

    .h3, .h4 {
      font-weight: bold;
      clear: left;
      min-height: 40px;
    }

    .h3 {
      font-size: 17px;
    }

    .h4 {
      font-size: 16px;
    }

    .company-name {
      color: @text-grey;
      display: block;
      margin-bottom: 5px;
      font-size: 14px;
      line-height: 1.1;
    }

    @media (max-width: @screen-sm-max) {
      a {
        height: 320px;
      }

      &.carousel a {
        height: 360px;
      }

      .company-logo {
        //float: none;
        margin: -55px auto 15px;
      }

      .h4 {
        font-size: 14px;
        line-height: 1.1;
      }

      .box-item-body {
        padding: 20px;
        //text-align: center;
      }

      .label-saving {
        margin-top: 0;
      }
    }

    @media (max-width: @screen-xs-max) {
      a {
        height: 300px;
      }

      &.carousel a {
        height: 340px;
      }
    }

    @media (min-width: @screen-md-min) {
      .label-saving {
        margin-top: -1px;
      }
    }
    /* PLUS VOUCHER STYLE */

    &.plus {
      background: @color-plus-gray-light;

      .h4 {
        color: @white;
      }

      .label-saving {
        border-color: @color-plus;
        color: @color-plus;
      }

      .company-name {
        color: @gray-lighter !important;
      }
    }
  }

  &.voucher-horizontal {
    height: auto;
    padding: @grid-gutter-width/2;
    margin: 0;
    box-shadow: none;

    .box-item-body {
      display: block;
      padding: 6px;
      margin-left: 95px;
    }

    .h4 {
      margin-bottom: 3px;
      font-size: 15px;
      @media (max-width: @screen-xs-max) {
        font-size: 14px;
      }
    }

    .company-logo {
      .box-shadow-small;
      width: 84px;
      height: 84px;
      float: left;
      border: 6px solid @white;
      position: relative;
      background: @white;
      top: auto;
      right: auto;
      padding: 0;
    }

    .company-name {
      color: @text-grey;
      display: block;
      margin-bottom: 5px;
      font-size: 13px;
    }
    .label-saving {
      margin: 0;
    }
  }

  /* Blogpost Box *********************************************************************** */

  &.blogpost {

    .category {
      position: absolute;
      left: 10%;
      top: 10%;
      font-size: 16px;
      color: @white;
    }

    .title {
      position: absolute;
      left: 10%;
      top: 35%;
      width: 80%;
      font-size: 30px;
      color: @white;
    }

    .btn-iams {
      position: absolute;
      left: 10%;
      bottom: 0;
    }

    // Small elements for sidebars
    &.small {
      .title {
        font-size: 18px;
        top: 30%;
      }
    }

    @media (max-width: @screen-sm-max) {
      text-align: center;
      .category, .title {
        width: 100%;
        left: 0;
        padding: 0 20px;
      }

      .category {
        font-size: 14px;
      }

      .title {
        top: 30%;
        font-size: 20px;
      }

      .btn-iams {
        width: 80%;
        left: 10%;
        bottom: 10%;
        padding: 15px 20px;
      }
    }
  }

  /* Blog Post Layout for /blog **** */

  &.blogpost-small {

    .h4 {
      font-size: 16px;
      margin: auto;
    }

    .box-item-body {
      display: flex;
      text-align: center;
      padding: 15px;
      height: 120px;
    }


    @media (max-width: @screen-sm-max) {
      .h4 {
        font-size: 14px;
      }

      &.horizontal {
        height: auto;


        .box-item-body {
          padding: 20px;
        }

        .h4 {
          font-size: 20px;
        }
      }
    }
  }

  /* Blog Post Layout for /blog **** */

  &.blogpost-list {
    height: 400px;
    text-align: center;

    .blogpost-tag-list {
      display: block;
      font-weight: bold;
      margin-bottom: @grid-gutter-width/4;

      a {
        display: inline;
      }
    }

    .box-item-body {
      display: block;
      padding: 20px;
    }

    .h4 {
      margin: auto;
    }


    @media (max-width: @screen-sm-max) {
      height: 150px;
      text-align: left;

      img {
        float: left;
        margin-right: @grid-gutter-width/2;
        width: 150px;
        height: 150px;
      }

      .box-item-body {
        padding: @grid-gutter-width/2 @grid-gutter-width/2 0;
      }

      &.horizontal {
        height: auto;
        text-align: center;

        img {
          width: 100%;
          margin: 0 0 15px 0;
          height: auto;
        }

        .box-item-body {
          padding: 20px;
        }

        .h4 {
          font-size: 20px;
        }
      }
    }
  }

  /* Challenge Box ********************************************************************** */

  //&.challenge {
  //  span {
  //    display: block;
  //    padding: 0;
  //    margin: 0;
  //  }
  //
  //  .company-logo {
  //    display: inline-block;
  //    width: auto;
  //    max-width: 100px;
  //    max-height: 100px;
  //    margin: 35px 20px;
  //  }
  //
  //  .box-item-body {
  //    padding: 30px;
  //
  //    background: -moz-linear-gradient(top, #f5f5f5 0%, #ffffff 100%);
  //    background: -webkit-linear-gradient(top, #f5f5f5 0%, #ffffff 100%);
  //    background: linear-gradient(to bottom, #f5f5f5 0%, #ffffff 100%);
  //    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6f2f2f2', endColorstr='#00000000', GradientType=0);
  //
  //    .h4 {
  //      font-size: 14px;
  //      margin: 0;
  //      float: left;
  //    }
  //
  //    .expires {
  //      float: right;
  //      margin-left: 10px;
  //      font-size: 14px;
  //      color: @text-grey;
  //    }
  //  }
  //
  //  &.vertical {
  //    span {
  //      width: 100%;
  //      text-align: center;
  //
  //      .box-item-body {
  //        padding: 20px 30px;
  //
  //        .expires {
  //          float: none;
  //          margin-left: 0;
  //          margin-top: 5px;
  //        }
  //      }
  //    }
  //
  //    .company-logo {
  //      max-height: 70px;
  //      max-width: 70px;
  //      margin: @grid-gutter-width/2;
  //    }
  //  }
  //
  //  @media (max-width: @screen-sm-max) {
  //    height: 320px;
  //    text-align: center;
  //
  //    .company-logo {
  //      max-height: 70px;
  //      max-width: 70px;
  //      margin: @grid-gutter-width/2;
  //    }
  //
  //    .box-item-body {
  //      padding: 20px;
  //    }
  //
  //    .box-item-body .expires {
  //      float: none;
  //      margin-left: 0;
  //      margin-top: 5px;
  //    }
  //  }
  //
  //}

  /* University Box ************************************************************************ */

  &.university {
    span {
      display: block;
      padding: 0;
      margin: 0;
    }

    .university-logo {
      display: inline-block;
      width: auto;
      max-width: 120px;
      max-height: 90px;
      margin: 40px 10px;
    }

    .university-image {
      height: 170px;
      background-position: center;
      background-size: cover;
    }

    .company-rating {
      font-size: 14px;

    }

    .bullet-separator {
      margin: 0 5px;
    }

    .box-item-body {
      padding: 30px;

      background: -moz-linear-gradient(top, #f5f5f5 0%, #ffffff 100%);
      background: -webkit-linear-gradient(top, #f5f5f5 0%, #ffffff 100%);
      background: linear-gradient(to bottom, #f5f5f5 0%, #ffffff 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6f2f2f2', endColorstr='#00000000', GradientType=0);

      min-height: 110px;

      .h4 {
        font-size: 14px;
        margin: 0;
        display: block;
      }

      span {
        display: inline;
      }
    }

    @media (max-width: @screen-sm-max) {
      height: 320px;
      text-align: center;

      .university-image {
        height: 120px;
      }

      .university-logo {
        height: 60px;
        width: auto;
        max-width: initial;
        margin: @grid-gutter-width/2;
      }

      .box-item-body {
        padding: 20px;
      }

      .company-rating ul {
        margin-bottom: -4px;
      }
    }
  }

  /* Company Box ************************************************************************ */

  &.company {
    a {
      height: 320px;
      text-align: center;
    }

    &.carousel a {
      height: 330px;
    }

    .follow {
      position: absolute;
      right: 0;
      display: block;
      padding: 10px !important;
      .box-shadow(-3px 3px 10px 0px rgba(0, 0, 0, 0.1));
      background: @white;
      z-index: 100;
      color: @text-grey;
      line-height: 1;
      border-bottom-left-radius: @border-radius-base;

      svg {
        height: 18px;
      }

      &:hover {
        color: @brand-primary;
      }

      &.active svg {
        color: @brand-primary;
        animation: pound 0.8s ease 2;
      }
    }

    .company-logo {
      display: block;
      padding: 40px;
      border-bottom: 1px solid #e0dfdf;

      img {
        height: 112px;
        width: 112px;
        margin: 0 auto;
      }
    }

    .box-item-body {
      display: block;
      padding: 20px 30px;

      background: -moz-linear-gradient(top, #fefefe 0%, #ffffff 100%);
      background: -webkit-linear-gradient(top, #fefefe 0%, #ffffff 100%);
      background: linear-gradient(to bottom, #fefefe 0%, #ffffff 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6f2f2f2', endColorstr='#00000000', GradientType=0);

      .h4 {
        font-size: 14px;
        margin: 0 0 5px;
      }
    }

    @media (max-width: @screen-sm-max) {
      a {
        height: 305px;
      }

      &.carousel a {
        height: 315px;
      }

      .company-logo img {
        height: auto;
      }
    }
  }

  /* Content Box *************************************************************************** */

  &.content {
    position: relative;
    height: ceil((555px - @grid-gutter-width)/2);
    margin-bottom: @grid-gutter-width - 1;
    overflow: hidden;

    img {
      width: 100%;
    }

    .box-item-body {
      position: absolute;
      left: 10%;
      top: 20%;
      width: 80%;
      color: @white;

      > span {
        font-size: 18px;
      }
    }

    .form-control {
      //border: none !important;
      &.error {
        border: 2px solid @error !important;
      }
    }

    h3 {
      color: @white;
      font-size: 32px;
      line-height: 1.1;
      text-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
    }

    .btn-iams {
      position: absolute;
      left: 10%;
      bottom: 0;
    }

    @media (min-width: @screen-md-min) {
      &:last-child {
        margin-bottom: 0;
      }
    }

    @media (max-width: @screen-xs-max) {
      height: auto;
      min-height: 200px;

      img {
        .mobile-centered-bg-image;
        transform: translate(-50%, -50%);
        //max-width: 100%;
      }

      .box-item-body {
        top: 10%;

        span {
          font-size: 15px;
        }
      }

      h3 {
        font-size: 24px;
      }

      .btn-iams {
        left: 10%;
        bottom: 10%;
        padding: 15px 20px;
      }
    }
  }

  /* Social Proof Widget */

  &.social-proof {
    .box-item-body {
      width: 30%;
    }

    .company-rating {
      float: none;
    }
  }

  @media (max-width: @screen-sm-max) {
    /* Newsletter Register Form ******/
    &.newsletter-register-form {
      min-height: 250px;
    }

    /* Social Proof Widget */
    &.social-proof {
      img {
        width: 100%;
      }

      .fa {
        font-size: 18px !important;
      }

    }
  }

  /* About Us Box ************************************************************************************* */

  &.about-us {
    background: #c2bebd;
    height: 555px;
    overflow: hidden;

    .box-item-body {
      top: 50%;
    }

    span a {
      font-weight: bold;
      text-decoration: underline;
      color: @white;
      display: inline-block;
    }

    @media (max-width: @screen-xs-max) {
      height: auto;

      .box-item-body {
        top: 23%;
      }
    }
  }

  /* Premium Voucher Boxes *************************************************************************** */

  &.voucher-premium-small,
  &.voucher-premium-big {
    a {
      //text-align: center;
      height: auto;
    }

    .company-logo {
      float: none;
      margin: -100px auto 0;
      width: 130px;
      height: 130px;
    }

    .h4 {
      margin-top: 25px;
      font-size: 18px;
    }

    .box-item-body {
      padding: 50px;
      @media (max-width: @screen-sm-max) {
        padding: 30px 30px 50px 30px;
      }
    }

    //
    //.company-rating,
    //.label.label-saving {
    //  float: none !important;
    //}
  }

  &.voucher-premium-big a {
    text-align: left;
  }

  @media (min-width: @screen-md-min) {
    &.voucher-premium-small a,
    &.voucher-premium-big a {
      height: 500px;
    }

    &.voucher-premium-big {
      text-align: left;

      .company-logo {
        margin: 13px;
        height: auto;
        .box-shadow(none);
        float: left;
      }

      .box-item-body {
        width: 100%;
        display: block;
        padding: 0;
        overflow: hidden;

        .voucher-infos {
          display: block;
          float: left;
          padding: 32px 27px;
          .bg-gradient-horizontal-grey;
        }

        .label-saving {
          position: absolute;
          right: 27px;
          bottom: 21px;
        }
      }

      .h4 {
        font-size: 18px;
        margin-top: 0;
      }
    }
  }

  /* Voucher Pro Boxes ******************************************************************************* */

  &.voucher-pro {
    a {
      height: 430px;
    }

    @media (max-width: @screen-sm-max) {
      a {
        height: auto;
      }
    }
  }

  /* Voucher Booklet Boxes *************************************************************************** */

  &.voucher-booklet {
    height: 310px;

    .h4 {
      font-weight: bold;
      margin-bottom: 5px;
      font-size: 16px;
    }

    .box-item-body {
      display: block;
      padding: 30px;
    }

    .btn {
      position: absolute;
      right: 10%;
      bottom: 0;
    }

    @media (max-width: @screen-sm-max) {
      height: 270px;

      .h4 {
        font-size: 14px;
      }

      .btn {
        width: 100%;
        right: 0;
      }

      .box-item-body {
        padding: 20px;
        text-align: center;
      }
    }
  }

  &.user-quote {
    .box-item-body {
      margin: @grid-gutter-width;
    }

    @media (max-width: @screen-sm-max) {
      height: 200px;

      .box-item-body {
        margin: @grid-gutter-width/2;
      }
    }
  }

  /* General Styles for all boxes ******************************************************* */

  .company-rating {
    float: left;

    @media (max-width: @screen-sm-max) {
      //float: none !important;
    }
  }

  .box-item-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    background: @brand-secondary;
    color: @white;
    font-size: 10px;
    text-align: center;
    padding: 4px;
    text-transform: uppercase;
    font-weight: normal;
    letter-spacing: 0.5px;

    &.studentweek, &.pro {
      background: @brand-primary;
    }

    &.top-pro {
      background: linear-gradient(135deg, @brand-secondary 25%, @brand-primary 75%);
    }

    &.plus {
      background: @color-plus;
    }

    &.knaller {
      background: @color-knaller;
    }

    &.studentweek.inactive {
      background: #2c353b;
    }

    &.footer-action {
      background: @brand-primary;
      padding: 8px;
    }

    span {
      display: inline-block;
    }
  }
}

/*
* Fix margin/padding left/right on mobile screens
*/
@media (max-width: @screen-sm-max) {
  .media-list.clearfix {
    .col-xs-6, .col-xs-12, .col-md-4 {
      padding-left: @grid-gutter-width/4;
      padding-right: @grid-gutter-width/4;
    }
  }

  .fullwidth-mobile {
    margin-left: -@grid-gutter-width/2;
    margin-right: -@grid-gutter-width/2;
  }

  .voucher-pro-list {
    padding: 0 20px;
  }
}