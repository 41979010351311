.label.label-saving {
  display: inline-block;
  color: #d31f39;
  border: 1px solid #d31f39;
  padding: 5px 8px;
  .border-radius(3px);
  text-transform: none;
  margin-top: 5px;
  vertical-align: middle;

  &.label-plus {
    border-color: @color-plus;
    color: @color-plus;
  }

&.label-invert {
  background-color: @brand-primary;
  color: @white;
  .transition(all 0.25s ease-in-out);
  &:hover {
    background-color: @brand-primary-darker;
  }
}

  //@media (max-width: @screen-sm-max) {
  //  float: none !important;
  //}
}

.label.label-new {
  display: inline-block;
  background-color: @yellow;
  color: @darkest-grey;
  .border-radius(4px);
  font-size: 10px;
  padding: 5px;
  margin-top: -2px;
  vertical-align: middle;
  &.label-primary {
    background-color: @brand-primary;
    color: @white;
  }
  &.label-secondary {
    background-color: @brand-secondary;
    color: @white;
  }
}


.label.label-default {
  display: inline-block;
  float: left;
  padding: 8px 10px;
  .border-radius(3px);
  font-weight: normal;
  margin: 0 10px 10px 0;
  text-transform: none;

  background-color: @button-default;
  color: @dark-grey;
  transition: background 0.3s ease-in;

  &:hover,
  &:active,
  &:focus {
    color: @dark-grey;
    background-color: darken(#e8e8e8, 5%);
  }

  &.active, &[data-toggle="collapse"]:not(.collapsed) {
    color: @white;
    background-color: @brand-secondary;
  }
}

.label.label-success, .label.label-success:hover {
  background: @success;
  color: @white;
}

.label.label-danger, .label.label-danger:hover {
  background: @brand-danger;
  color: @white;
}

.badge-default {
  font-weight: normal;
  background: #eae8eb;
  color: @dark-grey;
  margin-top: -4px;

  &.full-width {
    width: 100%;
  }
}
.label-nav-primary {
  color: @white !important;
  background: @iamstudent-primary-color;;
  &:hover,
  &:focus {
    background: darken(@iamstudent-primary-color, 5%) !important;
  }
  &:active {
    background: darken(@iamstudent-primary-color, 10%) !important;
  }
}
.label-nav-black {
  color: @white !important;
  background: @gray-dark;
  &:hover,
  &:focus {
    background: darken(@gray-dark, 5%) !important;
  }
  &:active {
    background: darken(@gray-dark, 10%) !important;
  }
}
/*
.label-nav-studiumfinden {
  color: @black !important;
  background: @yellow;;
  &:hover,
  &:focus,
  &:active {
    background: darken(@yellow, 5%) !important;
  }
}
.label-nav-studiwohnen {
  color: @white !important;
  background: @brand-secondary;
  &:hover,
  &:focus,
  &:active {
    background: darken(@brand-secondary, 5%) !important;
  }
}
.label-nav-plus {
  color: @black !important;
  background: @color-plus;
  &:hover,
  &:focus,
  &:active {
    background: darken(@color-plus, 5%) !important;
  }
}
.label-nav-summerdays {
  color: @black !important;
  background: @color-others-light;
  &:hover,
  &:focus,
  &:active {
    background: darken(@color-others-light, 5%) !important;
  }
}
*/