@embed-screen-xs-max: 549px;
@embed-screen-xs-max-outer: 699px;
@embed-bg-color: #ccc;

.embed-layout {
    background: @embed-bg-color;
    overflow: hidden;

    @media (max-width: @embed-screen-xs-max) {
        background: @white;
    }
}

/* Less padding in voucher description accordion */
.panel-body-accordion .voucher-embed-iframe-wrapper {
    margin: 0 -20px 10.5px;
    padding: 10px;
}

.voucher-embed-iframe-wrapper {
    margin: 0 -30px 10.5px;
    padding: 10px 30px;
    background: @embed-bg-color;
    line-height: 1;
    text-align: center;

    @media (max-width: @embed-screen-xs-max-outer) {
        background: @white;
        border-top: 2px solid #efefef;
        border-bottom: 2px solid #efefef;
        padding: 0;

        &:not(.embed-blog) {
            margin: 0 -@grid-gutter-width/2 10.5px;
        }
    }
}
.voucher-embed-iframe {
    width: 100%;
    height: 160px;
    overflow: hidden;
    border: none;

    @media (max-width: @embed-screen-xs-max-outer) {
        height: 320px;
        width: 300px;
    }
}

.embed-responsive-custom-slideshow {
    padding-bottom: 52.51%;
}

/* Inside iframe */
.voucher-embed-box {
    background: #fff;
    max-width: 800px;
    display: block;
    overflow: hidden;
    margin: 0 auto;

    h1 {
        font-size: 18px!important;
        margin-bottom: 5px;
    }

    .company-logo-embed  {
        float: right;
        margin-left: 10px;
        margin-bottom: 10px;
    }

    .content {
        padding: @grid-gutter-width/2;
        width: 58%;
        float: left;
    }

    .voucher-image {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        height: auto;
        width: 42%;
        float: left;

        @media (max-width: @embed-screen-xs-max) {
            width: 100%;
            background-size: auto 100%;
            float: none;

            img {
                width: 100%;
            }
        }
    }

    .embed-visible-xs {
        display: none;
    }
    .embed-visible-lg {
        display: block;
    }

    .bg-white {
        color: @text-color;
        font-size: 12px;
        padding-bottom: 3px;

        .embed-rating-mobile {
            float: right;
            width: auto;
            margin-top: 8px;

            .company-rating {
                display: inline-block;
            }
        }
    }

    @media (max-width: @embed-screen-xs-max) {
        h1 {
            font-size: 16px;
            margin-bottom: 4px;
            margin-top: 4px;
        }

        .content {
            padding: 0;
            width: 100%;
            float: none;
        }

        .embed-visible-xs {
            display: block;
        }
        .embed-visible-lg {
            display: none;
        }
    }
}