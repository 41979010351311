/*
 Structure of a Tiny Slider:
 .tns-outer
   .tns-controls (left, right buttons)
   .tns-ovh (content)
     .tns-inner
       ul[tiny-slider]
   .tns-nav (dots)
*/

.tns-outer {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    position: relative;

    .tns-inner {
        z-index: -1;
        margin-left: 0 !important;
    }

    .tns-slider {
        transition: transform 0.4s ease;
    }

    .tns-gallery {
        transition: none;

        .tns-item {
            left: 0 !important;
        }

        .tns-item.tns-fadeIn {
            transition: opacity 0.8s ease !important;
        }

        .tns-item.tns-fadeOut {
            transition: opacity 0.8s ease 0.8s !important;
        }
    }

    .tns-nav {
        margin: -10px auto 30px !important;
        display: flex;
        //width: 80%;
        z-index: 1;

        &.disabled {
            display: none;
        }
    }

    .tns-nav > button[aria-controls] {
        padding: 24px 0 !important;
        margin: 0;
        flex-grow: 1;
        background: none;

        &:after {
            transition: background-color 0.2s ease-in;
            background: @medium-grey;
            border-radius: 0;
            display: inline-block;
            content: '';
            margin: 0;
            height: 2px;
            width: 100%;
            position: relative;
            top: -15px;
        }

        &:focus {
            outline: none;
        }

        &:hover:after {
            background: @middle-grey;
        }

        &.tns-nav-active:after {
            background: @brand-secondary;
        }
    }

    .tns-controls {
        margin: 0;
        outline: 0;

        button {
            position: absolute;
            z-index: 1;
            border: none;
            background: none;
            padding: 15px 0 !important;
            top: 0;
            margin: 0;
            color: #d3d3d3;
            height: calc(100% - 72px);
            padding: @grid-gutter-width/2 !important;
            width: 50px;
        }

        .fa-icon {
            color: #d3d3d3;
            transition: color .2s ease-in;
        }

        svg {
            width: 22px;
            height: auto;
        }

        button[disabled] {
            opacity: 0;
            visibility: hidden;
        }

        button:hover .fa-icon {
            color: @brand-secondary;
            background: none;
        }

        button:focus {
            outline: none;
        }

        [data-controls="prev"] {
            left: -@grid-gutter-width*2;
        }

        [data-controls="next"] {
            right: -@grid-gutter-width*2;
        }
    }

    .tns-slider::-moz-focus-inner {
        border: 0;
    }

    // Make carousels scroll to device edges
    @media (max-width: @screen-sm-max) {
        margin: 0 -@grid-gutter-width;

        .tns-ovh {
            padding: 0 @grid-gutter-width;
        }

        .tns-nav {
            margin: -20px 32px 16px !important;
        }
    }
}

.carousel .tns-outer {
    position: static;
}