.bg-iamstudent {
    background: @iamstudent-primary-color;
}
.bg-iamgreen {
    background: @iamgreen-primary-color;
}
.bg-iamfemme {
    background: @iamfemme-primary-color;
}
.bg-schuelerrabatte {
    background: @schuelerrabatte-primary-color;
}
.bg-azubi-rabatte {
    background: @azubirabatte-primary-color;
}
.all, .red, .bg-brand-primary {
    background: @brand-primary !important;
}


.bg-light-grey {
    background: @light-grey;
}

.bg-studentweek {
    #gradient.vertical(#373f45, #2c353b);
}

.bg-yellow {
    background: @yellow;
}
/* Platformübergreifende Background Images */
.bg-white {
    background-color: @white;
}
.bg-plus {
    background-color: @color-plus;
}
.bg-plus-grey {
    background-color: @color-plus-gray;
}
.bg-plus-grey-light {
    background-color: @color-plus-gray-light;
}
/* FULL-PAGE BACKGROUNDS */
.bg-referral {
    background: #e0fafb;
}
/* LOGO */
.bg-logo {
    background-image: url("/assets/img/logo/@{platform}.svg");
}