/* Styles for all modals */
.modal .modal-content {
  .border-radius(3px) !important;
}
.modal-header .close {
  margin-top: 0;
}
.modal-primary {
  .modal-content {
    background-color: @brand-primary;
    border: none;
  }

  .modal-body {
    padding: @grid-gutter-width;

    @media (max-width: @screen-xs-max) {
      padding: @grid-gutter-width/2;
    }
  }

  .modal-header p {
    margin: 0;
  }

  .modal-header,
  .modal-footer {
    color: @white;
    padding: @grid-gutter-width/2;

    h2, h3 {
      color: @white;
      margin-bottom: 0;

      @media (max-width: @screen-xs-max) {
        font-size: 18px;
      }
    }
  }

  .modal-body {
    background-color: @white;
  }

  .modal-header .close {
    color: @white;
    //margin-top: -2px;
    opacity: 0.5;

    &:hover {
      color: @white;
      opacity: 1;
    }
  }
}


.modal-plus {
  h3, h4 {
    color: @white;
  }

  .modal-content {
    background-color: @color-plus-gray;
    color: @white;
  }
}

.modal .modal-content {
  border: none;
  .box-shadow(0 0 25px rgba(0, 0, 0, 0.3));
  .border-radius(0);
}

.modal.modal-non-blocking {
  top: auto;
  background: rgba(0, 0, 0, 0.5);

  @media (min-width: @screen-sm-min) {
    .modal-dialog {
      width: calc(100% - 20px);
      margin: 10px auto;
      max-width: 800px;
    }

    .modal-footer {
      padding: 10px @grid-gutter-width/2;
    }
  }
}


@media (min-width: @screen-sm-min) {

  .modal-sm {

    .modal-body {
      padding: 25px;

      hr {
        .margin-top;
        .margin-bottom;
      }
    }

    .modal-header,
    .modal-body,
    .modal-footer {
      padding-left: 35px;
      padding-right: 35px;
    }

  }

}

.login-modal-footer {
  text-align: left;
}

@media (max-width: @screen-xs-max) {
  //.modal {
  //  form {
  //    .form-control {
  //      padding: 2px 10px;
  //      height: 38px !important;
  //    }
  //
  //    input {
  //      font-size: 13px;
  //    }
  //
  //    textarea {
  //      padding: 8px;
  //    }
  //  }
  //}

  #iamstudent_form_rating_ratingtext {
    height: calc(1em + 20px);
    transition: height 0.15s;
  }

  #iamstudent_form_rating_ratingtext:focus {
    height: calc(5em + 20px);
  }
}

/* AZUBIRABATTE BACKDROP WALL MODAL */
.modal-wall {
  position: fixed;
  inset: 0;
  isolation: isolate;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;

  .blur {
    position: absolute;
    inset: 0;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
  }
  .terms {
    .border-radius(0 3px 0 0);
    position: fixed;
    bottom: 0;
    left: 0;
    background: #efefef;
    padding: 5px 10px;
  }
  .popup {
    position: absolute;
    max-width: 90%
  }
}