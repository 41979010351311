.grayscale {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
}

.fade-out {
  position: relative;
  max-height: 100px;
  overflow: hidden;
  border: transparent;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(rgba(245, 245, 245, 0) 20%, rgba(245, 245, 245, 1) 100%);
  }
}

.fade-out-white {
  position: relative;
  height: 100px;
  overflow: hidden;
  &.height-500 {
    height: 500px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 1) 100%);
  }
}

.mobile-centered-bg-image {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  min-height: 100%;
  min-width: 100%;
  transform: translate(-50%, -50%);
  max-width: none;
}

.max-height-element {
  overflow: hidden;
  opacity: 1.0;
  transition: all 0.5s ease-out;
}

.fade-out-max-height {
  max-height: 0 !important;
  opacity: 0.0 !important;
  transition: all 1s ease-out;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}


@media (hover: hover) {
  .highlight-box:hover {
    transition: opacity 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;

    .box-shadow(0px 5px 20px 0 rgba(0, 0, 0, 0.15)) !important;
    transform: translateY(-16px);
  }
}

@import "effects/loading-placeholder.less";
@import "effects/rubber-band.less";
@import "effects/shadows.less";
@import "effects/surprise.less";