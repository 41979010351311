/* FONTS ****************************************************** */
@font-family-base:                          -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
@font-family-light:                         @font-family-base;
@font-family-regular:                       @font-family-base;
@font-size-base:                            14px;
@font-size-jumbo:                           36px;
@base-font-color:                           #000;//#282828;
@fa-font-path:                              '../fonts'; // Font Path für font-awesome (public/css/fonts/)
@link-color:                                @brand-primary;
@text-color:                                @gray;
@headline-color:                            @black;

// Platform Colors
@iamstudent-primary-color:                  #d41f39; //#f82345;
@iamgreen-primary-color:                    #8cc63f; //#57AC35;
@iamfemme-primary-color:                    #b171d3; //#7c47d3;
@schuelerrabatte-primary-color:             #FFC107;
@azubirabatte-primary-color:                #84ce2e;
@iamstudent-academy-color:                  #598DF6;

// Reset inverted navbar basics
@navbar-inverse-color:                      @gray-light;
@navbar-inverse-bg:                         #fff;
@navbar-inverse-border:                     darken(@navbar-inverse-bg, 10%);

@navbar-height:                             90px;

// Sub/Footer navbar
@navbar-sub-height:                         41px;
@navbar-default-height:                     35px;
//@navbar-centered-height:                    35px;
@navbar-padding-vertical:                   ((@navbar-default-height - @line-height-computed) / 2);
@navbar-default-bg:                         #2a2a2a;
@navbar-default-link-color:                 #f5f5f5;
@navbar-default-link-hover-color:           #ffffff;
@navbar-default-link-hover-bg:              @brand-secondary-hover;
@navbar-default-link-active-color:          #ffffff;
@navbar-default-link-active-bg:             @brand-secondary-darker;
@navbar-default-border:                     none;

// Mobile Nav
@navbar-mobile-height:                      120px;

// Navbar toggle
@navbar-default-toggle-icon-bar-bg:         #dbdbdb;

// Inverted navbar links
@navbar-inverse-link-color:                 @gray-light;
@navbar-inverse-link-hover-color:           #fff;
@navbar-inverse-link-hover-bg:              @brand-primary;
@navbar-inverse-link-active-color:          @black;
@navbar-inverse-link-active-bg:             transparent;
@navbar-inverse-link-disabled-color:        #444;
@navbar-inverse-link-disabled-bg:           transparent;

// Inverted navbar brand label
@navbar-inverse-brand-color:                @navbar-inverse-link-color;
@navbar-inverse-brand-hover-color:          #fff;
@navbar-inverse-brand-hover-bg:             transparent;

// Inverted navbar toggle
@navbar-inverse-toggle-hover-bg:            #333;
@navbar-inverse-toggle-icon-bar-bg:         #fff;
@navbar-inverse-toggle-border-color:        #333;


// Number of columns in the grid system
@grid-gutter-width:                 30px;
@grid-float-breakpoint:             @screen-md-min;

@font-size-h3:                      ceil(@font-size-base * 1.5); // ~24px
@font-size-h2:                      ceil(@font-size-base * 1.8); // ~32px
@font-size-h1:                      ceil(@font-size-base * 2); // ~32px

@padding:                           5px;

/* PANELS ****************************************************** */
@panel-padding-body:                @grid-gutter-width * 2;
@panel-padding-heading:             @panel-padding-body @panel-padding-body 0 @panel-padding-body;
@panel-border-radius:               3px;

/* COLORS ****************************************************** */
@brand-primary-darker:              darken(@brand-primary, 5%);

// Brand Secondary Color / Komplementärfarbe
@brand-secondary:                   #1E65F5; //#598df6; // Default brand secondary
@brand-secondary-darker:            darken(@brand-secondary, 5%);
@brand-secondary-hover:             lighten(@brand-secondary, 10%); //  Buttons Hover
@brand-secondary-light:             lighten(@brand-secondary, 30%);

@white:                             #fff;
@black:                             #000;
@light-grey:                        #efefef;
@medium-grey:                       #e1e1e1;
@middle-grey:                       #ccc;
@dark-grey:                         #595959;
@form-grey:                         #e0dfdf;
@text-grey:                         #595959;
@darkest-grey:                      #282828;
@highlight-row:                     #fffec5;
@error:                             #f82345;
@success:                           #79c141;
@yellow:                            #fae251;
@button-default:                    #e8e8e8;
@text-muted:                        @dark-grey;
@footer-grey:                       @darkest-grey; //#141f37;

/* CATEGORIES */
@color-sparetime:                   #383866; // Freizeit
@color-travelling:                  #e9c417; // Reisen
@color-gastronomy:                  #4ed893; // Gastronomie
@color-fashion:                     #f75789; // Fashion
@color-electronics:                 #608aff; // Elektronik
@color-projects:                    #608aff; // Projekte
@color-others:                      #eb6d11; // #fbae18; // #ffcc01; // Sonstiges
@color-challenge:                   #89cbcf; // Gewinnspiele
@color-beauty:                      #bf7896; // Beauty


// Darker Categories Colors
@color-sparetime-darker:            darken(@color-sparetime, 5%);   // Freizeit
@color-travelling-darker:           darken(@color-travelling, 5%);  // Reisen
@color-gastronomy-darker:           darken(@color-gastronomy, 5%);  // Gastronomie
@color-fashion-darker:              darken(@color-fashion, 5%);     // Fashion
@color-electronics-darker:          darken(@color-electronics, 5%); // Elektronik
@color-projects-darker:             darken(@color-projects, 5%);    // Projekte
@color-others-darker:               darken(@color-others, 5%);      // Sonstiges
@color-challenge-darker:            darken(@color-challenge, 5%);   // Gewinnspiele
@color-beauty-darker:               darken(@color-beauty, 5%);      // Beauty

// Lighter Categories Colors
@color-sparetime-light:             lighten(@color-sparetime, 25%);   // Freizeit
@color-travelling-light:            lighten(@color-travelling, 15%);  // Reisen
@color-gastronomy-light:            lighten(@color-gastronomy, 15%);  // Gastronomie
@color-fashion-light:               lighten(@color-fashion, 15%);     // Fashion
@color-electronics-light:           lighten(@color-electronics, 10%); // Elektronik
@color-projects-light:              lighten(@color-projects, 10%);    // Projekte
@color-others-light:                lighten(@color-others, 17%);      // Sonstiges
@color-challenge-light:             lighten(@color-challenge, 10%);   // Gewinnspiele
@color-beauty-light:                lighten(@color-beauty, 10%);      // Beauty

// Social Media Colors
@color-facebook:                    #3b5998; // Facebook
@color-apple:                       #111111; // Apple
@color-google:                      #fff; // Google
@color-messenger:                   #1787fb; //#1787fb; // Facebook
@color-whatsapp:                    #4dc247; //#4dc247; // Whatsapp
@color-instagram:                   #bc2a8d; // Instagram
@color-ios-bg:                      #555555;
@color-android-bg:                  #a4c639;

// Knaller-Angebote
@color-knaller:                     #822EAF;

// Plus
@color-plus:                        #00d2d8; // #66fcf1;
@color-plus-darker:                 darken(@color-plus, 10%); // #66fcf1;
@color-plus-gray:                   #0b0c10; //#131313;
@color-plus-gray-light:             #47515e;
/* NAVIGATION */
@main-nav-bg:                       rgba(255, 255, 255, 0.9); // Main Navigation Background, transparent


// Static Header Images
@carousel-responsive-height:        200px;

@carousel-nav-bg:                   rgba(0, 0, 0, 0.3); // Carousel Navigation Background, transparent
@carousel-h1-bg:                    rgba(16, 14, 29, 0.8); // Carousel Headline Background, transparent

@content-top-margin:                @navbar-height + @grid-gutter-width;
@content-top-no-margin:             @navbar-height;

/* VOUCHER ***************************************************** */
@voucher-border-radius:             0px;
@voucher-border-color:              rgba(0, 0, 0, 0.8); // Voucher Border color, transparent
@voucher-title-bg:                  rgba(0, 0, 0, 0.7);

/* FRONTPAGE - FILTER */
@filter-category-box-size:          75px;

/* ALERTS */
@alert-padding:                     10px;

/* MODALS */
@modal-border-radius:               6px;
@modal-sm:                          400px;

@input-border-radius:               3px;
@alert-border-radius:               @input-border-radius;