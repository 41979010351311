/**
 * SLIDER
 */
.slider {

  .tns-outer .tns-controls button {
    height: calc(100%);
    &:after {
      top: calc(50% - 12.5px);
    }
  }

  @media (max-width: @screen-sm-max) {
    .tns-outer .tns-controls [data-controls="next"] {
      right: -10px;
    }

    .tns-outer .tns-controls [data-controls="prev"] {
      left: -10px;
    }

    .tns-outer .tns-controls button {
      height: calc(100% - 95px);
    }
  }

  @media (min-width: @screen-md-min) {
    margin-top: @grid-gutter-width/2;
    margin-bottom: @grid-gutter-width/2;

    .tns-inner {
      margin-right: -1px !important;
    }
  }

  .slider-carousel {
    margin: 0;
    height: 376px;
    overflow: hidden;

    .slider-item {
      background: @white;
      float: left;

      .slider-item-image {
        display: block;
        width: 63%;
        float: left;
      }

      .slider-item-content {
        width: 37%;
        float: left;

        h2 {
          font-size: 24px;
        }

        .slider-item-content-wrapper {
          padding: 25px 25px 0;
          height: 226px;
          position: relative;

          .btn {
            position: absolute;
            width: auto;
            left: 25px;
            right: 25px;
            bottom: 25px;
          }
        }
      }

      @media (max-width: @screen-sm-max) {
        .slider-item-image,
        .slider-item-content {
          width: 100%;
          height: auto;
        }
      }

      .company-info-wrapper {
        background: @white;
        position: relative;
        margin-bottom: 0;
        border-top: 1px solid rgba(245, 245, 245, 1);

        .company-wrapper {
          .bg-gradient-horizontal-grey;
        }

        @media (min-width: @screen-md-min) {
          margin-bottom: 40px;
        }
      }

      /* Desktop only */
      @media (min-width: @screen-md-min) {
        .logo-wrapper, .company-wrapper {
          float: left;
          width: 50%;
          padding: 25px;
          text-align: center;
          height: 150px;

          h4 {
            margin-top: 10px;
            font-size: 14px;
          }
        }

        .company-wrapper {
          padding-top: 15px;
        }
      }

      /* Mobile only */
      @media (max-width: @screen-sm-max) {
        .logo-wrapper,
        .company-wrapper {
          //float: left;
          height: 95px;
          padding: 10px;
        }

        .logo-wrapper {
          //float: left;
          width: 95px;
          position: absolute;
          top: 0;
          left: 0;

          img {
            width: 75px;
            height: 75px;
          }
        }

        .company-wrapper {
          margin-left: 95px;

          h4 {
            margin: 0 0 5px;
            font-size: 15px;
          }
        }

        .company-name {
          .box-item.voucher .company-name;
        }
      }
    }
    @media (max-width: @screen-sm-max) {
      // Slider carousel height fixing for mobile
      overflow: hidden;
      width: 100%;
      height: calc(52.6667vw + 100px);
    }
  }
}

/* Mobile only, full width only */
@media (max-width: @screen-sm-max) {
  .container-full-mobile .slider {
    .tns-outer .tns-controls button:after {
      text-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
      color: @white;
    }
  }
}