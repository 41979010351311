/* =Theme customization starts here
-------------------------------------------------------------- */
body.iam-blog {
  .cont-grid > .panel,
  .single-in.panel,
  #carousel-blog {
    .border-radius(0);
    @media (max-width: @screen-sm-max) {
      margin: 0 -@grid-gutter-width/2;
    }
  }

  &:not(.post-type-archive-iam_deals),
  &:not(.single-iam_deals) {
    padding-top: @content-top-no-margin;

    @media (max-width: @screen-sm-max) {
      padding-top: @navbar-mobile-height;
    }
  }

  @media (max-width: @screen-sm-max) {
    .single {
      .single-header {
        .border-radius(0);
      }
    }
  }

  div.post {
    padding: @grid-gutter-width;

    @media (max-width: @screen-sm-max) {
      padding: @grid-gutter-width/2;
    }

    .breadcrumb {
      margin: 0;
      padding: 0;
      line-height: 1.2;
      .text-xs;
      .text-muted;
    }
  }

  .blog-title {
    font-size: @font-size-h1*1.1;
    margin-bottom: 15px;
    @media (max-width: @screen-xs-max) {
      font-size: @font-size-h3;
      line-height: @font-size-h3 * 1.2;
      margin-bottom: @grid-gutter-width/4;
    }
  }

  .blog-item {
    margin-bottom: @grid-gutter-width;

    h2 {
      margin-top: 0;
    }
  }

  .sing-cont p,
  .grid-text p {
    text-align: justify;
  }

  .sing-cont {
    h1 {
      .blog-title;
    }
  }

  .single {
    font-size: 1.05em;
    line-height: 1.6em;
    @media (max-width: @screen-xs-max) {
      line-height: 1.5em;
    }

    .wp-caption-text {
      font-size: 0.8em;
      margin-top: 3px;

      .text-muted;
    }
  }

  .meta-bar {
    line-height: 1.4;

    a {
      display: inline-block;
    }
  }

  .single-header {
    .title {
      font-size: 40px;
      @media (max-width: @screen-sm-max) {
        font-size: 25px;
      }
    }
  }

  .recent-vouchers {
    h4 {
      font-size: 18px;
      line-height: 1.4em;

      .label {
        margin-left: 10px;
        margin-bottom: 10px;
      }
    }
  }

  .featured .item-featured {
    overflow: hidden;
    padding: 0;
    height: 400px;
  }

  /* Single Header */
  .featured .caption,
  .single-header .caption {
    bottom: 0;
    color: #fff;
    position: absolute;
    width: 100%;
    z-index: 100;
    text-shadow: 0 0 5px #000;
  }

  .single-header {
    background-size: cover;
    background-position: center center;
    overflow: hidden;
    position: relative;
    height: auto;

    .title {
      margin: 0;
      padding: 5px 20px 25px;
      text-transform: uppercase;
      color: #fff;
      text-shadow: 0 0 5px #000;
    }

    .date {
      font-size: 13px;
      padding: 0 20px;
      text-transform: uppercase;
      text-shadow: 0 0 5px #000;
    }

    .cat {
      color: #fff;
      font-size: 13px;
      padding: 0 20px;
      text-transform: uppercase;
      text-shadow: 0 0 5px #000;
    }

    .cat,
    .date,
    .title {
      padding-left: 25px;
      padding-right: 25px;
    }
  }

  .single-post {
    #authorarea {
      height: 400px;
    }
  }

  div.tags {
    margin-bottom: 10px;
    .label {
      float: left;
      margin: 0 8px 8px 0;
      line-height: 1.2;
      a {
        color: @white;
        font-size: 10px;
        font-weight: normal;
        &:hover {
          text-decoration: none;
        }
      }

      &:hover {
        background: @brand-primary-darker;
      }
    }
  }

  /* Post */
  .sing-cont .wp-caption {
    width: auto !important;
  }

  .wp-caption-text {
    white-space: normal !important;
    word-wrap: break-word !important;
  }

  /* Resize image to full width in boxes */
  .grid-cop {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }

  .tab-content a {
    border-bottom: 1px solid #ddd;
    float: left;
    min-height: 75px;
    padding: 10px 0 0;
    text-decoration: none;
    width: 100%;
  }

  /* POSTS */
  h2.grid-tit a:hover {
    color: #333;
  }

  /* Pagination */
  .pagination span,
  .pagination a {
    border: 1px solid #dedede;
    padding: 9px 14px;
    background: #fff;
    display: inline-block;
  }

  img.aligncenter {
    display: block;
    margin: 0 auto;
  }

  .blog-search-form {
    .inline-padded-form;
    .border-radius(0);

    input {
      width: 70% !important;
      padding: 0 5px;
    }
    button {
      width: auto;
      margin: 0;
      background: none;
      border: none;
      box-shadow: none;
      height: 32px;
      line-height: 1;
    }
  }

  .search-cont .form-control {
    height: 33px;
  }

  #wpadminbar {
    position: fixed;
    bottom: 0;
    top: auto;
  }

  /* Add text for post star ratings */
  .kk-star-ratings {
    width: 100%;
    margin-bottom: 10px;
    &:before {
      content: "Wie sehr hat dir dieser Artikel gefallen?";
    }
    .kksr-stars {
      width: 120px;
      margin-left: 10px;
    }

    .kksr-legend {
      text-align: left;
    }
  }

  /* Related Posts Plugin CSS */
  .related-posts-wrapper {
    background: @brand-primary;
    padding: @grid-gutter-width;

    @media (max-width: @screen-sm-max) {
      padding: @grid-gutter-width/2;
    }

    h3, a {
      color: #fff;
    }
  }

  .related_post_title {
    margin-bottom: 12px !important;
  }

  ul.related_post li {
    margin: 0 15px 0px 0px !important;
    width: 161px !important;

    a {
      font-size: 13px !important;
      margin-bottom: 0 !important;

      &:hover {
        color: #efefef;
        text-decoration: underline !important;
      }
    }

    img {
      box-shadow: none !important;
      .border-radius(0) !important;
      width: 100% !important;
      height: auto !important;
      margin-bottom: 3px !important;
    }

    @media (max-width: @screen-xs-max) {
      width: 50% !important;
      margin: 0 !important;
      padding: 0 2px 10px 2px !important;

      a {
        width: 100%;
        clear: both;
      }

      img {
        width: 100% !important;
        height: auto !important;
      }
    }

    &:last-child {
      margin-right: 0 !important;
    }
  }

  hr {
    margin: 12px 0;
  }

  .hide-kk-ratings {
    .kk-star-ratings {
      display: none;
    }
    br[clear='both'] {
      display: none;
    }
  }

  .blog-cat-headline {
    display: inline-block;
    color: inherit !important;
    position:relative;
    margin-bottom: @grid-gutter-width/2;

    &.blog-cat-studium:before {
      background-color: #5cd4f9;
    }
    &.blog-cat-studentenleben:before {
      background-color: #0ada4e;
    }
    &.blog-cat-wohnen:before {
      background-color: #de02fa;
    }
    &.blog-cat-reisen-studenten:before {
      background-color: #ffc607;
    }
    &.blog-cat-nachhaltigkeit:before {
      background-color: #37ccbb;
    }
    &.blog-cat-finanzen-studenten:before {
      background-color: #b475fa;
    }
    &.blog-cat-academy:before {
      background-color: @iamstudent-academy-color;
    }
  }

  a.blog-cat-headline:hover {
    text-decoration: underline !important;
  }

  .blog-cat-headline:before{
    content:"";
    width:100%;
    height:0.8em;
    position:absolute;
    z-index:-1;
    left:-0.3em;
    top:0.45em;


  }
  /**
   * ACADEMY Styles ****************************************************************************************************
   */
  &.category .main-content {
    overflow: unset !important;
  }

  &.category-academy {
    background: @white;
  }

  &.category:not(.category-academy) {
    background: #f8f8f8 !important;

    .academy-cat-list {
      background: #f8f8f8 !important;

      @media (max-width: @screen-sm-max) {
        padding: 0 !important;
      }
    }
  }

  .academy-header {
    background-color: @iamstudent-academy-color;
    background-image: url('/assets/img/iamstudent/layout/academy/iamstudent_academy_bg.png');
    background-repeat: no-repeat;
    background-position: top center;
    padding: 70px 10px 0;
    height: 800px;
    @media (max-width: @screen-sm-max) {
      height: 500px;
    }

    .academy-title {
      font-size: 48px;
      color: @white;
      @media (max-width: @screen-sm-max) {
        font-size: 36px;
      }
    }

    h2 {
      color: @white;
    }

    // Sub-Category page header
    &.is-subcategory {
      background-color: @white;
      background-image: url('/assets/img/iamstudent/layout/academy/iamstudent_academy_category_bg.png');
      padding: 0 10px;

      .academy-title {
        color: #333333;
      }

      h2 {
        color: #828282;
        font-size: 19px;
        font-weight: normal;
      }
    }
  }

  .academy-cat-logo-wrapper {
    margin: 20px 0;
    @media (max-width: @screen-sm-max) {
      margin: 10px 0;
    }
  }

  .academy-cat-list {
    background: @white;
    padding: @grid-gutter-width;
    margin-top: -400px;
    .border-radius(3px);

    @media (max-width: @screen-sm-max) {
      margin-top: -150px;
    }

    @media (min-width: @screen-lg-min) {
      margin-left: -30px;
      margin-right: -30px;
    }
  }

  .academy-item {
    background: #F8F8F8;
    padding: @grid-gutter-width/2;
    margin-bottom: @grid-gutter-width;
    .border-radius(3px);
    .box-shadow(0px 4px 6px 0 rgba(0, 0, 0, 0.10));
    text-align: center;

    @media (max-width: @screen-sm-max) {
      padding: 0;
      .box-shadow(none);
      border: 2px solid #E0E0E0;
      margin-bottom: @grid-gutter-width/2;
    }

    > a {
      height: 300px;
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      @media (max-width: @screen-sm-max) {
        display: none;
      }
    }

    h3 {
      font-size: 19px;
      a:hover {
        transition: color 0.5s ease-in-out;
        color: @iamstudent-academy-color;
      }
    }

    .academy-item-body {
      min-height: 98px;
      padding: @grid-gutter-width/2;
      background: @white;
      .border-radius(3px);

      @media (max-width: @screen-sm-max) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row-reverse;
      }
    }

    .academy-logo-wrapper {
      background: @white;
      padding: @grid-gutter-width/4;
      border: 1px solid #f2f2f2;
      position: relative;
      float: right;
      margin-top: -40px;
      max-width: 100px;
      height: 60px;
      display: flex;
      align-items: center;

      img {
        max-height: 55px;
      }

      @media (max-width: @screen-sm-max) {
        border: none;
        margin: 0;
        padding: 0;

        img {
          max-height: 80px;
          max-width: 100px;
        }
      }


    }
  }

  .academy-post-item {
    background: @white;
    .border-radius(3px);
    .box-shadow(0px 4px 6px 0 rgba(0, 0, 0, 0.10));
    margin-bottom: @grid-gutter-width;
    overflow: hidden;
    height: 400px;
    position: relative;

    @media (max-width: @screen-sm-max) {
      height: auto;
    }

    h4 {
      margin-top: 10px;
    }

    img {
      width: 100%;
    }

    .post-item-body {
      display: block;
      padding: @grid-gutter-width;
    }
    .academy-cat {
      color: @iamstudent-academy-color;
    }
    p {
      margin: 0;
    }
  }

  .academy-label {
    position: absolute;
    background: @white;
    right: @grid-gutter-width/2;
    top: @grid-gutter-width/2;
    .border-radius(3px);
    padding: 8px 16px;
  }

  .academy-company-logo {
    max-width: 160px;
    max-height: 80px;

    @media (max-width: @screen-sm-max) {
      max-width: 120px;
    }
  }

  .is-academy-post a {
    color: @iamstudent-academy-color;
    &:hover {
      color: darken(@iamstudent-academy-color, 10%);
    }
  }

  .academy-company-box {
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    padding: @grid-gutter-width/2 @grid-gutter-width;

    h4 {
      font-size: 15px;
    }

    @media (max-width: @screen-sm-max) {
      padding: @grid-gutter-width/2;
    }
  }
}