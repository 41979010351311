/**
 * Page About
 * URL: /ueber-uns
 */


// Benefits
.about-benefit {
  margin: 0 0 @grid-gutter-width 0;
  height: 170px;
  background: @white;
  padding: @grid-gutter-width;

  & > p:last-child {
    margin-bottom: 0;
  }

  @media (max-width: @screen-xs-max) {
    height: auto;

    img {
      width: 75px;
      height: 75px;
    }
  }

  @media (min-width: @screen-md-min) {
    height: 100%;
    margin: 0;
  }
}

// Polaroid Images
.about-polaroid {
  margin-top: -@grid-gutter-width;
  @media (min-width: @screen-md-min) {
    margin-top: -@grid-gutter-width * 3;
    float: right;
  }
}
