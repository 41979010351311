.no-margin {
    margin: 0 !important;
}
.no-float {
    float: none !important;
}
.margin {
    margin: @grid-gutter-width;
}
.margin-half {
    margin: @grid-gutter-width/2;
}
.margin-more {
    margin: @grid-gutter-width * 2;
}
.margin-top-huge {
    margin-top: @grid-gutter-width * 5;
}
.margin-top {
    margin-top: @grid-gutter-width;
}
.margin-top-5 {
    margin-top: 5px;
}
.margin-top-negative {
    margin-top: -@grid-gutter-width;
}
.margin-top-half {
    margin-top: @grid-gutter-width/2;
}
.margin-right {
    margin-right: @grid-gutter-width;
}
.margin-right-half {
    margin-right: @grid-gutter-width/2;
}
.margin-right-5 {
    margin-right: 5px;
}
.margin-left-5 {
    margin-left: 5px;
}
.margin-bottom-5 {
    margin-bottom: 5px;
}
.margin-left {
    margin-left: @grid-gutter-width;
}
.margin-left-half {
    margin-left: @grid-gutter-width/2;
}
.margin-bottom {
    margin-bottom: @grid-gutter-width;
}
.margin-bottom-md {
    @media (min-width: @screen-md-min) {
        margin-bottom: @grid-gutter-width;
    }
}
.margin-bottom-half-xs {
    @media (max-width: @screen-xs-max) {
        margin-bottom: @grid-gutter-width/2;
    }
}
.margin-horizontal-xs {
    @media (max-width: @screen-xs-max) {
        margin: 0 @grid-gutter-width;
    }
}
.margin-bottom-huge {
    margin-bottom: @grid-gutter-width * 5;

    @media (max-width: @screen-xs-max) {
        margin-bottom: (@grid-gutter-width * 5)/2;
    }
}
.margin-bottom-half {
    margin-bottom: @grid-gutter-width/2;
}
.margin-top-more {
    margin-top: @grid-gutter-width * 2;

    @media (max-width: @screen-xs-max) {
        margin-top: (@grid-gutter-width * 2)/2;
    }
}
.margin-bottom-more {
    margin-bottom: @grid-gutter-width * 2;

    @media (max-width: @screen-xs-max) {
        margin-bottom: (@grid-gutter-width * 2)/2;
    }
}
.mb-10 {
    margin-bottom: 10px;
}

.line-length-small {
    max-width: 35em;
}

.line-length-medium {
    max-width: 45em;
}

.line-length-large {
    max-width: 55em;
}

.line-length-huge {
    max-width: 64em;
}

@media (min-width: @screen-md-min) {
    .margin-top-md {
        margin-top: @grid-gutter-width;
    }

    .margin-top-half-md {
        margin-top: @grid-gutter-width/2;
    }

    .margin-top-negative-big-md {
        margin-top: -@grid-gutter-width*2;
    }
}

.no-padding {
    padding: 0;
}
.padding {
    padding: @padding;
}
.padding-top {
    padding-top: @grid-gutter-width;
}
.padding-top-half {
    padding-top: @grid-gutter-width/2;
}
.padding-bottom {
    padding-bottom: @grid-gutter-width;
}
.padding-double {
    padding: @padding * 2;
}
.padding-gutter {
    padding: @grid-gutter-width;
}

.anchor-offset,
h2 a[name],
h3 a[name],
h4 a[name] {
    scroll-margin-top: @navbar-height + 10px;

    @media (max-width: @screen-sm-max) {
        scroll-margin-top: @navbar-mobile-height + @navbar-sub-height + 10px;
    }
}

.p90 {
    width: 90%;
}

.block {
    display: block;
}
.inline-block {
    display: inline-block !important;
}
.relative {
    position: relative;
}
.absolute {
    position: absolute;
}
.of-hidden {
    overflow: hidden;
}
.of-unset {
    overflow: unset !important;
}
//.absolute.top {
//    top: 0;
//}
//.absolute.right {
//    right: 0;
//}
//.absolute.bottom {
//    bottom: 0;
//}
//.absolute.left {
//    left: 0;
//}
.color-inherit {
    color: inherit !important;
}
.corner-hint:before {
    .rotate(-45deg);
    display: inline-block;
    color: @white;
    font-weight: bold;
    text-align: center;
    width: 100px;
    height: 100px;
    margin-left: -100px;
    line-height: 1.2;
    padding-top: 9px;
}

.corner-hint-bonus:before {
    content: 'Geschenk gesichert';
}
.corner-hint {
    position: absolute;
    left: 0;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 0 100px 100px;
    border-color: transparent  transparent transparent @brand-primary;
}

.border-radius {
    .border-radius(@panel-border-radius);
}
.border-radius-top {
    .border-radius(@panel-border-radius @panel-border-radius 0 0);
}
.border-radius-bottom {
    .border-radius(0 0 @panel-border-radius @panel-border-radius);
}

.border-radius-top-more {
    .border-radius(2*@panel-border-radius 2*@panel-border-radius 0 0);
}

.border-top {
    border-top-width: 1px;
    border-top-style: solid;
}

.aspect-ratio-box {
    height: 0;
    overflow: hidden;
    padding-top: 46.666666667%; // aspect ratio => 15/7
    position: relative;
}

.absolute-fill-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.valign-children {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.halign-children {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.flex-wrap {
    flex-wrap: wrap;
}

.no-radius {
    .border-radius(0);
}

@media (max-width: @screen-sm-max) {
    .no-radius-mobile {
        .border-radius(0);
    }
    .position-inherit-mobile {
        position: inherit;
    }
}

.expand-bg-color {
    position: relative;
}

.expand-bg-color:before {
    content: '';
    display: block;
    background-color: white;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    transform: scaleX(100.0);
}
.ad-container {
    position: relative;
    z-index: -999;
    overflow: hidden;
}