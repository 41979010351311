@surprise-image-width: 335px;
@surprise-image-height: 263px;
@surprise-rotation: -35deg;

.surprise {
  .bg {
    position: fixed;
    z-index: 1020;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #24012b;
    pointer-events: none;
    opacity: 0.0;
  }

  &.anim .bg {
    animation-name: rating-bg-fading;
    animation-duration: 2.2s;
    animation-fill-mode: forwards;
  }

  .creature {
    display: none;
    width: @surprise-image-width;
    height: @surprise-image-height;
    left: -@surprise-image-width / 2;
    top: -@surprise-image-height / 2;
    position: fixed;
    z-index: 9999999;
    background: url('/assets/img/layout/jetpack_elefant.svg');
    background-position: center;
    transform-origin: center;
  }

  &.anim .creature {
    display: block;
    opacity: 0.0; // so it's not visible on animation delay
    animation-name: rating-reward-move;
    animation-duration: 4s;
    animation-delay: 1s;
    animation-timing-function: cubic-bezier(0.08, 1.28, 0.82, 0.48);
    animation-fill-mode: forwards;

    @media (orientation: portrait) {
      animation-name: rating-reward-move-mobile;
    }

    @media (min-width: @screen-md-min) {
      animation-name: rating-reward-move-big;
    }
  }
}

@keyframes rating-reward-move {
  0% {
    transform: translate(-10vw, 110vh) scale(1) rotate(@surprise-rotation);
    opacity: 1.0;
  }

  5% {
   transform: translate(-4vw, 104vh) scale(1.3) rotate(@surprise-rotation);
  }

  50% {
   transform: translate(58vw, 50vh) scale(1.0) rotate(@surprise-rotation);
  }

  100% {
    transform: translate(calc(100vw + @surprise-image-width/2 + 12px), calc(-@surprise-image-width/2 - 12px)) scale(0.1) rotate(@surprise-rotation);
    opacity: 1.0;
  }
}

@keyframes rating-reward-move-mobile {
  0% {
    transform: translate(calc(-@surprise-image-width), calc(80vh)) scale(1) rotate(@surprise-rotation);
    opacity: 1.0;
  }

  5% {
    transform: translate(-4vw, 77vh) scale(1.0) rotate(@surprise-rotation);
  }

  50% {
    transform: translate(58vw, 50vh) scale(1.0) rotate(@surprise-rotation);
  }

  100% {
    transform: translate(calc(98vw + @surprise-image-width / 2), calc(20vh)) scale(0.1) rotate(@surprise-rotation);
    opacity: 1.0;
  }
}

@keyframes rating-reward-move-big {
  0% {
    transform: translate(-10vw, 110vh) scale(1) rotate(@surprise-rotation);
    opacity: 1.0;
  }

  5% {
    transform: translate(-4vw, 104vh) scale(1.95) rotate(@surprise-rotation);
  }

  50% {
    transform: translate(58vw, 50vh) scale(1.5) rotate(@surprise-rotation);
  }

  100% {
    transform: translate(calc(100vw + @surprise-image-width/2 + 12px), calc(-@surprise-image-width/2 - 12px)) scale(0.15) rotate(@surprise-rotation);
    opacity: 1.0;
  }
}

@keyframes rating-bg-fading {
  0% {
    opacity: 0.0;
  }

  20% {
    opacity: 0.35;
  }

  80% {
    opacity: 0.35;
  }

  100% {
    opacity: 0.0;
  }
}