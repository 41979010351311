/* TEXT COLORS */
.text-iamstudent {
    color: @iamstudent-primary-color;
}
.text-iamfemme {
    color: @iamfemme-primary-color;
}

.text-@{platform} {
    color: @brand-primary;
}

.text-white {
    color: @white;
}
.text-black {
    color: @black;
}
.text-disabled {
    color: @gray-light;
}
.text-success {
    color: @success;
}
.text-warning {
    color: darken(@yellow, 10%);
}
.text-grey {
    color: @text-grey;
}
.text-grey-light {
    color: @middle-grey;
}
.text-grey-dark {
    color: @dark-grey;
}

.text-draft {
    color: @color-travelling;
}

.text-scheduled {
    color: @color-messenger;
}

.text-primary {
    color: @brand-primary;
}
.text-secondary {
    color: @brand-secondary;
}
.text-whatsapp {
    color: @color-whatsapp;
}

.text-facebook {
    color: @color-facebook;
}
.text-plus {
    color: @color-plus;
}

.text-knaller {
    color: @color-knaller;
}
.text-yellow {
    color: @yellow;
}