.company-logo {
    img {
        background: @white;
    }
    @media (min-width: @screen-sm-min) and (max-width: @screen-md-max) {
        img.company-logo-square:not(.disable-resize) {
            width: 110px;
            height: 110px;
        }
    }

    @media (max-width: @screen-sm-max) {
        img.company-logo-square:not(.disable-resize) {
            width: 50px;
            height: 50px;
        }
    }
}

.company-rating {
    display: inline-block;
    color: @black;
    font-size: 12px;
    font-weight: bold;

    ul {
        display: inline-block;
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            float: left;

            &.rating-count, &.rating-value {
                padding-top: 2px
            }

            .fa-icon {
                color: #eae8eb;
            }

            &.filled .fa-icon {
                color: @yellow;
            }
        }
    }

    &.company-rating-lg {
        svg {
            height: 24px;
            width: 24px;
        }
    }
}
.is-plus-voucher .company-rating,
.plus .company-rating {
    color: @white;
}

.rating-wrapper {
    position: absolute;
    right: @grid-gutter-width/2;
    bottom: 15px;

    .company-rating ul li {
        color: #eae8eb;
    }
    @media (max-width: @screen-sm-max) {
        position: relative;
        text-align: left;
        bottom: 0;
        left: 0;
        margin: 3px 0;

        .company-rating {
            display: inline-block;
        }
    }
}

.rating-wrapper, .voucher-embed-box {
    .company-rating i {
        margin: 1px;
        .text-sm;

        @media (min-width: @screen-md-min) {
            .text-xl;
        }
    }
}