.nav-tabs {
  border-bottom: none;
  text-align: center;

  li.active {
    font-weight: bold;
    a, a:hover, a:focus {
      border-color: @white;
    }
  }

  > li > a {
    border: none;
  }

  > li.disabled a {
    color: @dark-grey;
  }

  .tab-icon {
    display: none;
  }

  @media (max-width: @screen-sm-max) {
    li a {
      padding: 5px 8px;
    }

    .tab-icon {
      display: block;
      font-size: 20px;

      &.tab-icon-small {
        font-size: 16px;
      }
    }
    .tab-label {
      .text-xs;
    }
  }
}

/* USER PROFILE NAVIGATION */
.nav-tabs-user {
  border: 0;

  li {
    margin: 0;

    a {
      padding: 11px;
      color: #555555;
      font-size: @font-size-small;
      margin: 0;
      border: 0 !important;
      .border-radius(0);
    }

    @media (max-width: @screen-sm-max) {
      text-align: center;

      a {
        padding: 7px;
      }

      // Default 5 tabs
      width: 20%;
    }
  }

  @media (max-width: @screen-sm-max) {
    &.nav-tabs-user-4 li {
      width: 25%;
    }
    &.nav-tabs-user-3 li {
      width: 33.3333%;
    }

  }

  > li {
    &.active {
      > a,
      > a:hover,
      > a:focus {
        background: none;
        color: @black;
        font-weight: bold;
        box-shadow: 0 -3px 0px @brand-primary inset;
        .transition(all 0.25s ease-in-out);
      }
    }

    > a:hover,
    > a:focus {
      background: none;
      color: @black;
      box-shadow: 0 -3px 0px @light-grey inset;
      .transition(all 0.25s ease-in-out);
    }
  }

  @media (min-width: @screen-md-min) {
    &:not(.no-indent) {
      margin-left: 120px;
    }
  }
}

.nav-tabs-secondary {
  border-bottom: 2px solid #e0dfdf;

  > li {

    margin-bottom: -2px;
    border-bottom: 2px solid transparent;

    > a,
    > a:hover,
    > a:focus {
      color: @black;
      font-weight: bold;
      padding: 20px 25px;
      background: none;
      border: none;
      margin: 0;

      @media (max-width: @screen-sm-max) {
        padding: 8px;
        font-size: 12px;

        .info {
          position: absolute;
          left: 0;
          top: -10px;
          width: 100%;
        }
      }
    }

    > a:hover {
      color: @brand-secondary;
    }

    &.active {
      border-bottom: 2px solid @brand-secondary;

      > a {
        color: @brand-secondary !important;
        background: none !important;
        border: none !important;
      }
    }
  }
}

.nav-tabs-academy-wrapper {
  background: @white;
  margin: @grid-gutter-width 0;
  .border-radius(3px);
  border: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  @media (max-width: @screen-sm-max) {
    margin: @grid-gutter-width/2 0;
  }

  .academy-brand {
    display: block;
    width: 85px;
    height: 39px;
    margin-left: 10px;
    background-image: url('/assets/img/iamstudent/layout/academy/iamstudent_academy_logo_small.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center left;
  }

  .nav-spacer {
    width: 85px;
    height: 39px;
    margin-right: 10px;
    @media (max-width: @screen-sm-max) {
      display: none;
    }
  }

  .nav-tabs-academy {
    @media (max-width: @screen-sm-max) {
      overflow: auto;
      overflow-y: hidden;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
    }

    li {
      a {
        border: none !important;
        padding: 20px 0;
        color: @white;
        margin: 0;
        color: #828282;
        .border-radius(0);
        margin: 0 5px;

        @media (max-width: @screen-sm-max) {
          font-size: 13px;
          padding: 12px 0;
        }
      }
    }

    > li {
      &.active {
        > a,
        > a:hover,
        > a:focus {
          color: @black;
          box-shadow: 0 -3px 0px @iamstudent-academy-color inset;
          .transition(all 0.25s ease-in-out);
        }
      }

      > a:hover,
      > a:focus {
        background: transparent;
        color: @black;
        .transition(all 0.25s ease-in-out);
      }
    }
  }
}

//@media (max-width: @screen-sm-max) {
//  .panel .tab-content .tab-pane {
//    margin: 0;
//  }
//}