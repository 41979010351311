.textbubble {
    background: white;
    padding: 1em 1.5em;
    margin-bottom: calc(1em + 4px);
    transform: skewX(-12deg);
    box-shadow: 3px 4px 0 0 currentColor;
}

.textbubble:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -1em;
    left: 1em;
    width: 0;
    height: 0;
    border-right: 2em solid transparent;
    border-top: 1em solid white;
}

// shadow
.textbubble:before {
    content: '';
    display: block;
    position: absolute;
    bottom: calc(-1em - 4px);
    left: calc(1em + 2px);
    width: 0;
    height: 0;
    border-right: 2em solid transparent;
    border-top: 1em solid currentColor;
}

.textbubble > * {
    transform: skewX(12deg);
}

.textbubble > p:last-child {
    margin-bottom: 0;
}