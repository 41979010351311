@dark: #c5f5f7;
@light: #e0fafb;

.page-friends {
    margin-left: 0;
    margin-right: 0;
    width: 100%;

    .title .h1 {
        width: 70%;
        margin: auto;
    }

    .light-bg {
        background: @dark;
        padding: 19px
    }

    .turkis-color {
        color: @color-plus;
    }

    .panel-heading {
        padding: 0;
        margin-bottom: 1em;
    }

    .title {
        max-width: 600px;
        margin: 0 auto;
        margin-bottom: @grid-gutter-width/2;
    }

    .well {
        border-radius: 0;
        border: 0;
        margin-bottom: 0;
    }

    .tns-outer .tns-controls button {
        color: @navbar-default-bg;
    }

    .title-manderl {
        max-width: 80%;
    }

    .title-manderl-no-user {
        img {
            position: relative;
            width: 22vw;
            max-width: 130px;
        }
    }

    .nouser-bubble {
        transform: translate(-6%, -50%);

        .textbubble:after {
            left: -1.5em;
            border-left: 2em solid transparent;
            border-top: 1em solid white;
            top: 1em;
            z-index: -1;
        }
        .textbubble:before {
            display: none;
        }
    }

    .friends-get-voucher {
        margin: 0 -15px;
        position: relative;

        h1 {
            color: white;
            padding: 19px 30px;
            font-weight: normal;
            max-width: 60rem;
            margin: auto;

            &.expand-bg-color:before {
                background-color: @color-plus;
            }
        }
    }

    .btn-share {
        font-size: 19px;
        padding: 12px;
    }

    .textbubble-container {
        padding-left: 24%;
        padding-right: 10%;
    }

    .textbubble {
        max-width: 500px;
        font-size: 3.5vw;
    }

    .info-block {
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;
        align-items: baseline;
        font-size: 3.4vw;
        max-width: 400px;
        padding-right: 2em;
        padding-top: 1.5em;
    }

    .info-block p {
        padding-left: 0.5em;
    }

    .cta-moving {
        display: block;
        padding: 30px;
        width: 419px;
        transform: rotate(-5deg);
        animation: give-me-attention;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-timing-function: cubic-bezier(0.45, 0.05, 0.55, 0.95);
    }

    .reflink-container {
        max-width: 1400px;
        margin: auto;
    }


    @media (min-width: @screen-xs-max) {
        padding-top: 0;

        .title {
            margin-bottom: 0;
            min-height: 450px;
        }

        &.page-friends-hello .title {
            margin-bottom: 1em;
            min-height: auto;
        }

        .sunburst-container {
            height: 100%;
        }

        .sunburst {
            margin-bottom: 0;
            width: 150%;
            transform: scaleX(1.5);
        }

        .studo-gutschein-container {
            position: absolute;
            right: -100px;
            top: calc(50% - 201px);
            z-index: 1;
            max-width: 65%;
        }

        .studo-gutschein-container .btn.btn-plus {
            bottom: 0;
            left: 65%;
            transform: translate(-50%, 20px) rotate(-2deg);
            z-index: -1;
            font-size: 1.8em;
        }

        &:not(.page-friends-hello) .h1 {
            position: absolute;
            bottom: 46%;
            max-width: 60%;
            left: -12%;
        }

        &.page-friends-hello h1 {
            margin: 0 auto;
            margin-top: 2em;
            float: none !important;
        }

        .title-manderl {
            position: absolute;
            bottom: @grid-gutter-width/2;
        }

        .textbubble {
            font-size: 24px;
        }
        .info-block {
            font-size: 18px;
        }

        .friends-get-voucher {
            text-align: center;
            padding-bottom: @grid-gutter-width;
        }

        .nouser-bubble.textbubble-container {
            padding-left: 150px;
            padding-right: 100px;
        }
    }

    @media (min-width: @screen-sm-max) {
        overflow: visible;

        .sunburst {
            transform: scaleX(2.5);
        }

        .h1 {
            left: -25%;
        }

        .title-manderl {
            left: -13%;
        }
    }

}

.sunburst {
    width: 100%;
    height: 100%;
    overflow: hidden;
    transform: scaleX(1.75);


    .inner {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .gradient-wrap {
        width: 100%;
        height: 50%;
        overflow: hidden;
    }

    .gradient-wrap:last-child {
        transform: rotate(180deg);
    }

    .gradient {
        width: 100%;
        height: 200%;
        background:
                linear-gradient(180deg, transparent 50%, @dark 50%, @dark 100%),
                linear-gradient(170deg, transparent 50%, @light 50%, @light 100%),
                linear-gradient(160deg, transparent 50%, @dark 50%, @dark 100%),
                linear-gradient(150deg, transparent 50%, @light 50%, @light 100%),
                linear-gradient(140deg, transparent 50%, @dark 50%, @dark 100%),
                linear-gradient(130deg, transparent 50%, @light 50%, @light 100%),
                linear-gradient(120deg, transparent 50%, @dark 50%, @dark 100%),
                linear-gradient(110deg, transparent 50%, @light 50%, @light 100%),
                linear-gradient(100deg, transparent 50%, @dark 50%, @dark 100%),
                linear-gradient( 90deg, transparent 50%, @light 50%, @light 100%),
                linear-gradient( 80deg, transparent 50%, @dark 50%, @dark 100%),
                linear-gradient( 70deg, transparent 50%, @light 50%, @light 100%),
                linear-gradient( 60deg, transparent 50%, @dark 50%, @dark 100%),
                linear-gradient( 50deg, transparent 50%, @light 50%, @light 100%),
                linear-gradient( 40deg, transparent 50%, @dark 50%, @dark 100%),
                linear-gradient( 30deg, transparent 50%, @light 50%, @light 100%),
                linear-gradient( 20deg, transparent 50%, @dark 50%, @dark 100%),
                linear-gradient( 10deg, transparent 50%, @light 50%, @light 100%),
                linear-gradient(  0deg, transparent 50%, @dark 50%, @dark 100%);
    }
}

@keyframes give-me-attention {
    0% {
        transform: rotate(-5deg) scale(0.95);
    }

    50% {
        transform: rotate(-3deg) scale(1.0);
    }

    100% {
        transform: rotate(-5deg) scale(0.95);
    }
}