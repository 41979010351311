.strike {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    margin: @grid-gutter-width/2 0;
    clear: both;

    > span {
        position: relative;
        display: inline-block;
        color: @text-grey;

        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            width: 9999px;
            height: 1px;
            background: #eeeeee;
        }

        &:before {
            right: 100%;
            margin-right: 15px;
        }

        &:after {
            left: 100%;
            margin-left: 15px;
        }

    }
}

.strike-no-text {
    height: 1px;
    background: @button-default;
    margin: @grid-gutter-width/2 0;
}
