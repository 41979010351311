ul.socialmedia {
  li {
    float: left;
  }

  .btn {
    margin: 0 5px 5px 0;
    padding: 13px;
    font-size: 16px;
    width: 42px;
    height: 42px;
  }
}

/* google maps style fix */
#google-map .gm-style img {
  max-width: none !important;
}

/* Rating */
.rating-stars-container {
  font-size: 32px;

  @media (min-width: @screen-md-min) {
    a:hover .fa {
      animation: pound 0.8s ease infinite;
      transform-origin: center;
    }
  }

  @media (max-width: @screen-xs-max) {
    font-size: 32px;
  }
}

.voucher-iframe-container {
  position: fixed;
  top: @navbar-height + @navbar-margin-bottom;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  @media (max-width: @screen-xs-max) {
    top: @navbar-height;
  }
}

.voucher-iframe {
  border-top: 1px solid #ccc;
}

// Horizontal Voucher and Challenge Component
.voucher-horizontal,
.challenge-horizontal {
  h3 {
    font-size: 18px;
    @media (max-width: @screen-xs-max) {
      font-size: 16px;
    }
  }

  .voucher-content {
    padding: @grid-gutter-width/2 @grid-gutter-width/2 @grid-gutter-width/2 0 !important;
  }

  .btn-iams {
    clear: both;
  }

  @media (max-width: @screen-xs-max) {
    .company-logo {
      background: #fff none repeat scroll 0 0;
      position: absolute;
      top: @grid-gutter-width/2;
      right: @grid-gutter-width;
      padding: 4px;
    }

    .voucher-content {
      padding: @grid-gutter-width/2 !important;
    }

    .voucher-img {
      width: 100%;
      height: auto;
    }
  }

  &.full-width {
    .voucher-content {
      padding: @grid-gutter-width/2 !important;
    }
  }
}

/**
 * NEW: Voucher Detail
 */
.voucher-detail {
  .header-bg {
    width: 100%;
    height: 530px;
    background: @black;
    overflow: hidden;
    position: relative;

    .image-bg {
      position: absolute;
      width: 100%;
      opacity: 0.5 !important;
      height: auto;

      @media (min-width: @screen-lg-min+200) {
        top: -50%;
      }
    }
  }

  .share-button-container {
    position: absolute;
    right: 0;
    top: -49px;

    .btn {
      margin-left: 5px;

      &:first-child {
        margin-left: 0;
      }
    }

    @media (max-width: @screen-sm-max) {
      position: relative;
      right: auto;
      top: auto;
      margin-top: @grid-gutter-width/2;

      .btn {
        padding: 12px;
      }
    }
  }

  .modal .share-button-container {
    position: relative;
    top: auto;
  }

  @media (min-width: @screen-md-min) {
    .main-content > .row > .col-xs-12 > .box-shadow-medium {
      height: 530px;
    }
  }

  .header-voucher {
    position: relative;
    background: @white;
    padding: 70px;

    h1 {
      margin-bottom: 5px;
      font-size: 28px;
      &.long-title {
        font-size: 24px;
      }
      @media (max-width: @screen-sm-max) {
        font-size: 22px;
      }
    }

    .infos {
      line-height: 1;
      color: @text-grey;
      text-align: center;
      margin-top: @grid-gutter-width;

      .value {
        font-size: 20px;
        color: @black;
        font-weight: bold;
        margin-left: 7px;
        @media (max-width: @screen-sm-max) {
          font-size: 16px;
        }
      }

      .value, small {
        display: inline-block;
        vertical-align: middle;
      }

      @media (max-width: @screen-sm-max) {
        margin-top: @grid-gutter-width/2;
      }
    }

    @media (max-width: @screen-sm-max) {
      padding: 0 @grid-gutter-width/3*2 @grid-gutter-width/3*2;
    }

    @media (min-width: @screen-md-min) {
      margin-bottom: 40px;
    }
  }

  .company-info-wrapper {
    background: @white;
    position: relative;

    @media (max-width: @screen-sm-max) {
      padding: @grid-gutter-width/2 @grid-gutter-width/3*2 0;
    }

    @media (min-width: @screen-md-min) {
      margin-bottom: 40px;
    }
  }

  .image-wrapper {
    img {
      width: 100%;
      max-height: 292px;
    }
  }

  .logo-wrapper {
    background: @white;

    img {
      border: 6px solid @white;
      background: @white;
    }
  }

  /* Desktop only */
  @media (min-width: @screen-md-min) {
    .logo-wrapper, .company-wrapper {
      float: left;
      width: 50%;
      padding: 53px 50px;
      text-align: center;
      height: 238px;

      h4 {
        margin-top: 10px;
        font-size: 17px;
      }
    }

    .logo-wrapper {
      padding: 45px;
    }

    .company-wrapper {
      .bg-gradient-horizontal-grey;
    }
  }

  /* Mobile only */
  @media (max-width: @screen-sm-max) {
    .logo-wrapper {
      float: left;
      margin: -35px @grid-gutter-width/2 @grid-gutter-width/2 0;

      img {
        width: 84px;
        height: 84px;
        .box-shadow-small;
        border: 6px solid @white;
      }
    }

    .company-wrapper {
      h4 {
        margin: 0;
        font-size: 16px;
        a {
          display: inline-block;
          height: 24px;
        }
      }
    }

    .voucher-content {
      padding: 0 @grid-gutter-width/2;

      h3 {
        margin-top: @grid-gutter-width/4;
      }
    }
  }

  /* Voucher/Company Ratings */
  .voucher-rating-wrapper {
    background: @white;
    padding: @grid-gutter-width @grid-gutter-width*1.5;
    margin-bottom: @grid-gutter-width;
    .border-radius(@panel-border-radius);

    .voucher-rating-carousel-control {
      font-size: 24px;
      position: absolute;
      top: 5px;
      color: @black;
      padding: 30px 15px;

      &.left {
        left: -35px;
      }

      &.right {
        right: -35px;
      }
    }
  }

  .voucher-rating {
    blockquote {
      background: #f5f5f5;
      .border-radius(10px);
      font-weight: bold;
      padding: @grid-gutter-width @grid-gutter-width/2;
      border: none;
      font-size: 15px;
      margin: 10px 0;
    }
  }

  @media (min-width: @screen-md-min) {
    #voucherAccordion {
      margin: 0 10px;
    }
  }
}

/* Carousel Test with voucher use button */
#voucher-photos-carousel .carousel-inner a:hover .btn-photo-voucher-use {
  opacity: 1;
}

.btn-photo-voucher-use {
  position: absolute;
  opacity: 0;
  left: 20px;
  bottom: 20px;
}

/* Photo Carousel prev/next controls */
.voucher-carousel-control {
  position: absolute;
  bottom: 20px;
  border: 1px solid rgba(255, 255, 255, 0.7);
  color: @white;
  height: 40px;
  width: 40px;
  transition: all .4s ease-in;
  text-align: center;

  .fa-icon {
    padding: 8.5px;
    display: block;
  }

  &.left {
    right: 60px;
    left: auto;
    .border-radius(@input-border-radius 0 0 @input-border-radius);
  }

  &.right {
    right: 20px;
    .border-radius(0 @input-border-radius @input-border-radius 0);
  }

  &:hover {
    border-color: @white;
    text-decoration: none;
    color: @gray-dark;
    background: @white;
  }
}

#google-map {
  border: 6px solid @white;
}

.voucher-store {
  height: 100px;
}

/* PLUS STYLES */
.is-plus-voucher {
  .company-info-wrapper,
  .logo-wrapper,
  .company-wrapper,
  .header-voucher,
  h1,
  h4,
  h4 a,
  .infos .value {
    background: @color-plus-gray;
    color: @light-grey !important;
  }

  .company-wrapper {
    background: linear-gradient(to right, @color-plus-gray-light 0%, @color-plus-gray 15%, @color-plus-gray 100%);
  }

  .header-voucher p a:not(.btn) {
    color: @color-plus;
    &:hover {
      color: @color-plus-darker;
    }
  }

  .header-voucher .text-muted {
    color: @white;
  }
}