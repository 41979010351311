.panel {
  border: none;
  .box-shadow(none);

  &.panel-slim {
    .panel-heading {
      padding: @grid-gutter-width @grid-gutter-width 0 @grid-gutter-width;
    }

    .panel-title {
      font-size: 20px;
    }

    .panel-body {
      padding: 20px @grid-gutter-width @grid-gutter-width @grid-gutter-width;
    }

    margin-bottom: @grid-gutter-width/2;
  }

  &.panel-transparent {
    background: none;
  }

  &.panel-no-bg {
    background: none;

    > .panel-body {
      padding: 0;
    }
  }

  .panel-heading {
    background-color: transparent;
    border: none;
    padding: @panel-padding-heading;

    @media (max-width: @screen-xs-max) {
      padding: @panel-padding-body/2 @panel-padding-body/2 0;
    }

    .badge {
      margin-top: 2px;
    }
  }

  .panel-head {
    background: #f5f5f5;
    padding: @panel-padding-body/2;

  }

  .panel-title {
    font-size: @font-size-h1;
    margin: 0;
    @media (max-width: @screen-sm-max) {
      font-size: ceil(@font-size-h1/1.25);
    }
  }

  .panel-body {
    padding: @panel-padding-body/3*2 @panel-padding-body;
    @media (max-width: @screen-xs-max) {
      padding: @panel-padding-body/6*2 @panel-padding-body/2;
    }
  }

  .panel-body-slim {
    padding: @panel-padding-body/2;
    @media (max-width: @screen-sm-max) {
      padding: @panel-padding-body/4;
    }
  }

  .panel-footer {
    background-color: #eae8eb;
  }
}

.modal {
  .panel {
    .box-shadow(0 0 1px 0 rgba(0, 0, 0, 0.1));
  }
}

.panel-group .panel.panel-accordion {
  background: none;
  margin: 0;

  .panel-heading {
    padding: 0;
  }

  .panel-title {
    text-transform: uppercase;
    font-size: 13px;

    a {
      display: block;
      padding: 25px 0;

      border-top: 1px solid @medium-grey;
      border-bottom: 1px solid transparent;
    }

    a:hover,
    a:active,
    a:focus,
    a:not(.collapsed) {
      color: @brand-secondary;
      text-decoration: none;
    }

    a.collapsed {
      .rotate-icon {
        transform: rotate(180deg);
      }
    }
  }

  .panel-heading + .panel-collapse > .panel-body {
    border: none;
  }

  .rotate-icon {
    float: right;
    margin-top: -5px;
    color: @dark-grey;
  }

  .panel-body-accordion {
    padding: 0;

    h2 {
      font-size: 24px;
    }
  }

  &:first-child a {
    border: none;
  }
}