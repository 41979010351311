.promo-bar {
  background: @brand-secondary;
  .transition(all 0.25s ease-in-out);
  margin: 0;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 999;

  a {
    .transition(all 0.25s ease-in-out);
    text-align: center;
    color: @white;
    padding: 22px @grid-gutter-width;
    display: block;
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    position: relative;


    &:hover {
      background: @brand-secondary-darker;
      color: @white;
      text-decoration: none;
    }

    .promo-bar-close,
    .promo-bar-icon {
      position: absolute;
      line-height: 1;
      top: 0;
    }

    .promo-bar-close {
      left: 0;
      color: @black;
      font-size: 32px;
      left: 0;
      padding: 10px @grid-gutter-width/2;
    }

    .promo-bar-icon {
      right: 0;
      color: @white;
      font-size: 22px;
      padding: 18px @grid-gutter-width/2;
    }
  }

  @media (max-width: @screen-sm-max) {
    a {
      font-size: 13px;
      padding: 13px @grid-gutter-width;

      .promo-bar-close {
        font-size: 20px;
        padding: 8px @grid-gutter-width/2 12px;
        z-index: 10;
        #gradient.horizontal(@brand-secondary, rgba(89, 141, 246, 0), 66%, 100%);
      }
      .promo-bar-icon {
        display: none;
      }
      .promo-bar-title {
        display: inline-block;
        min-width: 100%;
        width: auto;
        text-align: left;
        animation: marquee 10s linear infinite;
      }
    }
  }
}