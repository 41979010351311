.fw-header {
  color: @white;

  .fw-header-user {
    background: @brand-primary;
  }
  .fw-header-nav {
    background: @white;
    margin-bottom: @grid-gutter-width/2;

    .dropdown.dropdown-right .dropdown-menu {
      left: auto;
      right: 0;
    }
  }

  .fw-header-logo {
    .pull-left;
    margin: 16px 15px -25px 0;

    @media (max-width: @screen-sm-max) {
      margin: 0 10px 0 0;
    }

    .profile-image-wrapper {
      font-size: 0;
      line-height: 0;
      .box-shadow-tiny;
    }

    .profile-image {
      .border-radius(0);
      border: none;

      @media (max-width: @screen-sm-max) {
        height: inherit;
        width: inherit;
      }
    }

    a {
      display: block;

      .img-wrap {
        background: @black;
        color: @white;
        line-height: 0;
        font-size: 26px;

        @media (max-width: @screen-sm-max) {
          font-size: 0;
        }
      }

      .fw-header-upload-link {
        position: absolute;
        width: 100%;
        bottom: 0;
        padding: 7px 10px;
        background: rgba(0, 0, 0, 0.7);
        font-size: 12px;
        text-align: center;
      }
    }
  }

  .fw-header-title {
    padding: @grid-gutter-width/2 0;
    //width: @container-lg - @grid-gutter-width/2 - 120;

    @media (max-width: @screen-sm-max) {
      padding: @grid-gutter-width/3;
      height: @profile-image-size-lg;
      //width: auto;
    }

    h1 {
      color: inherit;
      margin: 0;

      @media (max-width: @screen-sm-max) {
        line-height: 1.1;
        font-size: 20px;
      }
    }
  }

  .fw-header-subtitle {
    color: @medium-grey;
    margin: 0;
    font-weight: normal;

    @media (max-width: @screen-sm-max) {
      font-size: 13px;
    }
  }

  .breadcrumb {
    padding: 8px 0 0 0;

    li::before, a, span {
      color: @white;
      font-size: 12px;
    }

    @media (max-width: @screen-sm-max) {
      display: none;
    }
  }

  .fw-header-button {
    position: absolute;
    right: @grid-gutter-width/2;
    bottom: 40px;

    @media (max-width: @screen-sm-max) {
      right: 10px;
      bottom: 10px;
    }
  }
}