.countdown-text-padding {
  padding: 35px;

  @media (max-width: @screen-xs-max) {
    padding: 10px 0 0;
  }
}

.countdown {

  @media (max-width: @screen-xs-max) {
    &.margin {
      margin: @grid-gutter-width/4;
    }

    .text-extra-jumbo {
      font-size: 22px !important;

      &:first-child {
        margin-left: 0 !important;
      }
    }

    .text-lg {
      font-size: 12px !important;
    }

    .margin-right-half {
      margin-right: 0;
    }
  }
}

.btn-countdown {
  margin-top: 5px;
  @media (min-width: @screen-sm-min) {
    margin-bottom: 5px;
  }
}

.box-item.studentweek-countdown {
  .box-item-body {
    padding: @grid-gutter-width;
    text-align: center;
  }

  @media (max-width: @screen-xs-max) {
    .box-item-body {
      padding: @grid-gutter-width/2;
    }

    .countdown-label {
      display: block;
    }

    .visible-xs {
      display: inline !important;
    }
  }

}

.box-item.studentweek-newsletter,
.box-item.studentweek-countdown,
.box-item.hightlight-content {
  height: auto;
  @media (min-width: @screen-md-min) {
    height: 500px;
  }
}
