@media (max-width: @screen-sm-max) {
    .overflow-hidden,
    .search-results-header {
        // to prevent horizontal scrolling
        overflow: hidden;

        h1 {
            margin-bottom: 16px;
        }
    }
}

/* SMARTPHONE */
@media (max-width: @screen-xs-max) {
    .btn-full-width-responsive {
        width: 100%;
        margin-bottom: 7px !important;
        margin-left: 0;
        margin-right: 0;
        &.no-margin {
            margin: 0 !important;
        }
    }

    .btn-block-mobile {
        display: block;
    }

    .text-center-xs {
        text-align: center;
    }
}

@media (max-width: @screen-sm-max) {

    .text-right-mobile {
        text-align: right !important;
    }
}
@media (min-width: @screen-sm-min) {
    .pull-right-sm {
      float: right !important;
    }
    .pull-left-sm {
      float: left !important;
    }
}
@media (min-width: @screen-md-min) {
    .pull-right-md {
      float: right !important;
    }
}
@media (min-width: @screen-lg-min) {
    .pull-right-lg {
      float: right !important;
    }
}