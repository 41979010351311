.maincarousel {
  .carousel-indicators li,
  .carousel-indicators li.active {
    background: rgba(255, 255, 255, 0.5);
    width: 11px;
    height: 11px;
    margin: 2px;
    border: none;

    &.active {
      background: rgba(255, 255, 255, 0.85);
    }
  }

  &#carousel-blog {
    .carousel-indicators {
      right: 50%;
      left: auto;
      bottom: -5px;
      width: auto;
    }
  }

  .carousel-control {
    width: 7%;
    bottom: 0;

    .fa-icon {
      position: absolute;
      top: 40%;
      font-size: 72px;

      &.angle-left {
        left: 20%;
      }

      &.angle-right {
        right: 20%;
      }

      @media (max-width: @screen-xs-max) {
        font-size: 36px;
      }
    }
  }

  .carousel-caption {
    bottom: 0;
    left: 0;
    width: 100%;
    //background: @carousel-nav-bg;
    padding: @grid-gutter-width;
    text-shadow: none;
    .text-left;

    @media (max-width: @screen-sm-max) {
      padding: @grid-gutter-width/2;
    }

    .title {
      display: inline-block;
      margin: 0;
      font-size: 36px;
      padding: 10px 15px;
      background: @white;
      color: @black;

      @media (max-width: @screen-sm-max) {
        font-size: 22px;
      }
    }
  }

  .carousel-inner {
    .item {
      img {
        width: 100%;
      }

      &:hover .title {
        text-decoration: underline;
      }
    }
  }

  /* Guarantee correct image height for this image aspect ratio */

  &.carousel-booklet .carousel-inner .item {

    img {
      opacity: 1 !important;
      transform: translateY(-100%);
    }
  }
}

.carousel-primary {
  .carousel-indicators li {
    background: #e0dfdf;
    margin: 2px;
    padding: 6px;
    height: 11px;
    width: 11px;
    border: none;

    &.active {
      background: @brand-secondary;
    }
  }
}

.carousel-user-quotes {
  .item {
    height: 250px;

    @media (max-width: @screen-xs-max) {
      height: 280px;
    }
  }
}


.tns-controls [disabled] {
  color: #999999;
  background: #B3B3B3;
  cursor: not-allowed !important;
}

.tns-nav {
  text-align: center;
  margin: 10px 0;
}

.tns-nav > [aria-controls] {
  width: 9px;
  height: 9px;
  padding: 0;
  margin: 0 5px;
  border-radius: 50%;
  background: #ddd;
  border: 0;
}

.carousel-inner {
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}