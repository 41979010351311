img.img-border {
    border: 10px solid @white;
}

img[data-src], [unveil] {
  opacity: 0;
  transition: opacity .2s ease-in;
}

.gray-images img,
.grayscale-content img:not(.colored) {
    .grayscale;
}


@profile-image-size-small: 36px;
@profile-image-size: 60px;
@profile-image-size-xs: 50px;
@profile-image-size-md: 85px;
@profile-image-size-lg: 110px;

.profile-image {
    .inline-block;
    .text-center;
    .img-circle;
    overflow: hidden;
    width: @profile-image-size;
    height: @profile-image-size;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: @white;

    span {
        line-height: @profile-image-size;
    }

    &.profile-image-small {
        width: @profile-image-size-small;
        height: @profile-image-size-small;

        span {
            line-height: @profile-image-size-small;
        }
    }

    &.profile-image-lg {
        width: @profile-image-size-lg;
        height: @profile-image-size-lg;

        img {
            width: @profile-image-size-lg;
            height: @profile-image-size-lg;
        }

        span {
            line-height: @profile-image-size-lg;
            font-size: 30px;
        }

        @media (max-width: @screen-xs-max) {
            width: @profile-image-size;
            height: @profile-image-size;

            span {
                line-height: @profile-image-size;
            }
        }
    }

    @media (max-width: @screen-xs-max) {
        width: @profile-image-size-xs;
        height: @profile-image-size-xs;

        span {
            line-height: @profile-image-size-xs;
        }
    }
}
