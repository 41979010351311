.svg-icon svg {
  height: 30px;
  max-width: 35px;
  margin: 0 auto;
  path, rect {
    fill: currentColor;
    stroke: currentColor;
  }
}
.fa-icon svg {
  height: 14px;
  width: 16px;
  vertical-align: middle;
  //vertical-align: top;
  path, rect {
    fill: currentColor;
    stroke: currentColor;
  }
}
.fa-icon.icon-sm svg {
  height: 12px;
  width: 14px;
}
.fa-icon.icon-lg svg {
  height: 24px;
  width: 26px;
}
.fa-icon.icon-xl svg {
  height: 40px;
  width: 42px;
}
.fa-spin svg {
  animation: fa-spin 1s infinite linear;
}