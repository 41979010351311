form, form.form-horizontal {
  margin: 0;

  label.control-label {
    font-weight: normal;
    color: @black;
    font-size: 16px;
    text-align: left !important;
    padding-top: 14px;

    @media (max-width: @screen-sm-max) {
      padding-top: 5px;
    }

  }

  .has-error .control-label {
    color: @error;
  }

  .has-error .form-control {
    border-color: @error
  }

  .form-control,
  textarea {
    .border-radius(2px);
    padding: 0 12px;
    height: 43px;
    text-align: left;

    &.input-sm {
      height: 43px;
      padding: 0 8px;
    }

    &.input-xs {
      height: 31px;
      padding: 0 8px;
      font-size: 12px;
    }

    &.form-control-transparent {
      background: transparent;
      padding: 0;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 2px solid @white !important;
      color: @white;

      &::placeholder {
        color: @light-grey;
      }
    }

    &:focus {
      color: @black;
      border-color: @black;
      .box-shadow(none);
      outline: none;
    }

    &.error,
    &.ng-invalid-pattern {
      border-color: @error;
    }
  }

  .form-control,
  textarea,
  .input-group-addon,
  .embedded-form-border {
    border: 1px solid @form-grey;
    .border-radius(@input-border-radius);
    box-shadow: none;
  }

  textarea {
    padding: 12px;
  }

  select.form-control {
    padding: 12px;

    -webkit-appearance: none;
    -moz-appearance: none;
    background-position: right 50%;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
  }

  .checkbox {
    margin: 0 0 10px 0 !important;
  }

  .form-group > div > i {
    &.fa {
      color: @black;
      font-size: 16px;
      line-height: 3;

      &.fa-asterisk {
        color: @error;
      }
    }
  }

  .form-control-counter {
    position: absolute;
    top: 16px;
    right: 20px;
    color: @gray;
    font-size: 13px;

  }

  .form-control-feedback {
    height: auto;
    right: 10px;
  }

  textarea {
    width: 100%;
    height: auto;
  }

  .hidden-form-element {
    display: none;
  }

  input[type="radio"] + label,
  input[type="checkbox"] + label {
    margin: 5px 15px 0 7px;
  }

  input[type="file"] {
    line-height: 0;
  }

  /* Schmale Forms - weniger Abstände */

  &.slim {
    label.control-label {
      padding-top: 8px;
      @media (max-width: @screen-sm-max) {
        padding-top: 0;
        margin-bottom: 2px;
        font-size: 14px;
      }
    }

    select.form-control {
      padding: 10px 15px;
    }

    .form-control {
      height: 45px;
      @media (max-width: @screen-sm-max) {
        height: 40px;
      }
    }

    .form-group > div > i.fa {
      line-height: 2.1;
    }
  }
  .radio-vertical span {
    display: block;
  }
}

#registrierung_user_email {
  text-align: center !important;
}

input[readonly] {
  &.default-cursor {
    cursor: default;
    background-color: @white;
  }
}

/* FILTER */
.filter-bar {
  background: @white;
  .border-radius(0 0 @input-border-radius @input-border-radius);
  margin-bottom: 24px;

  h3 {
    margin: 24px 0 0 0;
    font-size: 22px;
    display: block;
    color: @text-color;
  }

  .filter-search-box {
    border-top: 1px solid @navbar-default-link-color;
    margin-top: -1px;
    padding: 15px;

    .input-group {
      width: 100%;
    }

    .form-control {
      .border-radius(@input-border-radius) !important;
      padding: 0 35px 0 45px;
      border: 2px solid @navbar-default-link-color;
      height: 45px;
      box-shadow: none;
      .transition(border 0.25s ease-in-out);

      &:hover, &:focus {
        color: @headline-color;
        //border-color: @medium-grey;
        border: 2px solid @brand-secondary !important;
      }
    }

    .btn {
      position: absolute;
      left: 0;
      top: 0;
      background: none;
      border: none;
      box-shadow: none;
      height: 45px;
      line-height: 1;
      z-index: 10;

      &.btn-right {
        right: 0;
        left: auto;
      }

      .fa {
        color: #555555;
      }
    }
  }

  @media (max-width: @screen-sm-max) {
    margin-left: -@grid-gutter-width/2;
    margin-right: -@grid-gutter-width/2;

    margin-bottom: 16px;
  }
}


ul.filter-voucher-type {
  margin: 0;
  padding: 0 15px;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  list-style: none;
  text-align: center;
  height: @filter-category-box-size;
  //float: right;

  li {
    a {
      color: @text-color;
      padding: 13px 2px 8px;
      //margin-right: 8px;
      //display: inline-block;
      .transition(all 0.3s ease-in-out);
      height: @filter-category-box-size;
      width: auto;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      min-width: 80px;


      i {
        font-size: 30px;
        display: block;
      }

      span {
        font-size: 10px;
      }

      &:hover, &:active, &:focus {
        text-decoration: none;
        color: @headline-color;

        &:not(.active) {
          background: @navbar-default-link-color;
          .transition(all 0.25s ease-in-out);
        }
      }

      &.plus {
        color: @color-plus;
      }

      &.active {
        color: @white;
        background: @brand-secondary;

        &.plus {
          background: @color-plus;
        }
      }

      &.small-filter {
        padding: 5px;
        margin-right: 0;
        height: @filter-category-box-size/2;
        width: @filter-category-box-size/2;
        min-width: @filter-category-box-size/2;
        line-height: 2.6;

        span {
          font-size: 10px;
        }
      }
    }
  }

  @media (max-width: @screen-sm-max) {
    width: 100%;
    height: @filter-category-box-size - 15;
    padding: 0;

    &.has-side-fadeout {
      &:after,
      &:before {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        right: 15px;
        width: 20px;
        height: @filter-category-box-size - 15;
        background: linear-gradient(90deg, #ffffff00, #ffffff);
        z-index: 2;
        pointer-events: none;
      }

      &:before {
        left: 15px;
        right: auto;
        background: linear-gradient(270deg, #ffffff00, #ffffff);
      }
    }

    li {
      height: auto;
      display: inline-flex;

      &:first-child {
        margin-left: 15px;
      }

      &.last-filter {
        border-right: 15px solid @white;
      }

      a {
        padding: 8px 3px 4px;
        min-width: 65px;
        height: 100%;

        i {
          font-size: 18px;
        }

        span {
          font-size: 10px;
        }
      }
    }
  }

  @media (max-width: @screen-xs-max) {

    li {
      a span {
        font-size: 8px;
      }
    }
  }
}

@media (max-width: @screen-xs-max) {
  ul.filter-tags {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
  }
}


.search-results-filter {
  margin-top: 5px;

  .label-default {
    .pull-right;

    @media (max-width: @screen-sm-max) {
      .pull-left;
    }

  }

  @media (max-width: @screen-sm-max) {
    margin-bottom: 10px;
  }
}

.embedded-form {
  .inline-padded-form;

  input {
    height: 46px;
  }

  button {
    margin: 2px 2px 0 0;
  }
}

.search-results-loading {
  .transition(all 0.2s ease-in-out);
  .opacity(0.7);
}

.search-results-loading-spinner {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 10;
  color: @headline-color;
}

.input-group.input-group-responsive {
  input, button {
    height: 43px !important;
  }

  .form-group {
    margin: 0;
  }
}

@media (min-width: @screen-md-min) {
  .input-group.input-group-responsive .input-group-btn .form-group {
    margin-left: -@input-border-radius;
  }
}

@media (max-width: @screen-sm-max) {
  .input-group.input-group-responsive .input-group-btn .form-group {
    padding-top: @grid-gutter-width/4;
  }
}

@media (max-width: @screen-xs-max) {
  .input-group.input-group-responsive .input-group-addon,
  .input-group.input-group-responsive .input-group-btn,
  .input-group.input-group-responsive .form-control,
  .input-group.input-group-responsive .btn {
    display: block;
    margin-bottom: 0;
    clear: both;
    width: 100%;
    text-align: center;

    &:last-child > .btn {
      margin-left: 0;
      .border-radius(1px);
    }
  }

  .input-group.input-group-responsive {
    display: block;
  }

  .text-sm-mobile {
    .text-sm;
  }
}

@media (max-width: @screen-xs-max) {
  form.text-center-xs input {
    text-align: center;
  }

  .line-height-huge-xs {
    line-height: 2.2;
  }
}