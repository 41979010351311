.show-participated,
.show-participated-block {
    .hidden;
}

.participated {
    .hide-participated {
        .hidden;
    }

    .show-participated {
        .inline-block;
    }

    .show-participated-block {
        .show;
    }
}
@media (max-width: @screen-sm-max) {
    .challenge > .container {
        padding: 0;
    }
}

#challenge-participate-modal .voucher-content h3 {
    font-size: 19px;
    margin-top: 0;
}
