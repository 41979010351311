.border-radius(@radius: 3px) {
    border-radius: @radius;
}

//.background(@color: @white, @alpha: 1) {
//  background-color: hsla(hue(@color), saturation(@color), lightness(@color), @alpha);
//}

//.text-shadow (@string: 0 1px 3px rgba(0, 0, 0, 0.75)) {
//    text-shadow: @string;
//}

// the retina mixin. An example goes: .at2x(img/header@2x.png, 650px, 150px, top, left, no-repeat, scroll)
// Put the size that the image appears on a non-retina, e.g. if it's a 200px x 200px non-retina, that is the effective size. The @2x 400px x 400px is calculated automatically. The order to type it is width then height.
// A modification of the retina.js less mixin - https://github.com/imulus/retinajs


//@highdpi: ~"(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx)";
//    .at2x(@path, @w: auto, @h: auto, @xpos: 0, @ypos: 0, @repeat: no-repeat, @attachment: scroll) {
//    background-image: url(@path);
//    background-position: @xpos @ypos;
//    background-repeat: @repeat;
//    background-attachment: @attachment;
//    @at2x_path: ~`"@{path}".split('.').slice(0, "@{path}".split('.').length - 1).join(".") + "@2x" + "." + "@{path}".split('.')["@{path}".split('.').length - 1]`;
//    @media @highdpi {
//        background-image: url(@at2x_path);
//        background-size: @w @h;
//        background-position: @xpos @ypos;
//        background-repeat: @repeat;
//        background-attachment: @attachment;
//    }
//}

@keyframes pound {
    from, to { transform: none; }
    50% { transform: scale(1.3); }
}
