.alert {
    margin-bottom: @grid-gutter-width/2;
}

.nav-alert {
    margin-bottom: 0;
    .border-radius(0);

    &.nav-fixed {
        position: fixed;
        top: @navbar-height;
        width: 100%;
        left: 0;
        z-index: 1021;
    }

    @media (max-width: @screen-sm-max) {
        .text-sm;
        padding: 8px 0;
        .close {
            margin-top: -3px;
        }
        &.nav-fixed {
            top: @navbar-mobile-height;
        }
    }
}

.alert-margin-bottom .nav-alert {
    margin-bottom: @grid-gutter-width;

    @media (max-width: @screen-sm-max) {
        margin-bottom: @grid-gutter-width/2;
    }
}

.alert.alert-bubble {
    padding: 3px 6px;
    font-weight: bold;
    .border-radius(3px);
    font-size: 10px;
}

.alert ul {
    padding: 0;

    li {
        list-style: none;
    }
}

.alert h4 {
    font-size: 16px;
}

.alert-default {
    color: @dark-grey;
    background-color: @light-grey;
    border-color: @medium-grey;
}